/* eslint-disable */

import afterPayment from '@assets/screen/afterPayment.png';
import alreadyAmember from '@assets/screen/alreadyAmember.png';
import checkInScreen from '@assets/screen/checkInScreen.png';
import checkout_cartInBag from '@assets/screen/checkout-cartInBag.png';
import curbside from '@assets/screen/curbside.png';
import forgotPasswordScreen from '@assets/screen/forgotPasswordScreen.png';
import itemDetailScreen from '@assets/screen/itemDetailScreen.png';
import joinNow_emailAddress from '@assets/screen/joinNow-emailAddress.png';
import joinNow_phoneNumber from '@assets/screen/joinNow-phoneNumber.png';
import addCardPage from '@assets/screen/addCardPage.png';
import joinNow from '@assets/screen/joinNow.png';
import loyaltyScreen from '@assets/screen/loyaltyScreen.png';
import moreScreen from '@assets/screen/moreScreen.png';
import myAccountScreen from '@assets/screen/accountScreen.png';
import online from '@assets/screen/online.png';
import onlineDelivery from '@assets/screen/onlineDelivery.png';
import onlinePickup from '@assets/screen/onlinePickup.png';
import orderHistoryScreen from '@assets/screen/orderHistoryScreen.png';
import orderScreen from '@assets/screen/orderScreen.png';
import orderScreenTableOrder from '@assets/screen/orderScreenTableOrder.png';
import payLater from '@assets/screen/payLater.png';
import payLaterScreen from '@assets/screen/payLaterScreen.png';
import payLaterSuccessMessageScreen from '@assets/screen/payLaterSuccessMessageScreen.png';
import paymentScreen_applyCouponAndLoyalty from '@assets/screen/paymentScreen-applyCouponAndLoyalty.png';
import payNow_emailScreen from '@assets/screen/payNow-emailScreen.png';
import payNow_smsScreen from '@assets/screen/payNow-smsScreen.png';
import payNowScreen from '@assets/screen/payNowScreen.png';
import payNow from '@assets/screen/payNow.png';
import payNowSuccessMessage from '@assets/screen/payNowSuccessMessage.png';
import phoneNumber_alreadyAmember from '@assets/screen/phoneNumber-alreadyAmember.png';
import profileScreen from '@assets/screen/profileScreen.png';
import qrCodeCheckScreen from '@assets/screen/qrCodeCheckScreen.png';
import receiptPopUp from '@assets/screen/receiptPopUp.png';
import signInScreen_account from '@assets/screen/signInScreen-account.png';
import signInScreen_phoneNumber from '@assets/screen/signInScreen-phoneNumber.png';
import tableOrder_homePage from '@assets/screen/tableOrder-homePage.png';
import upsellingScreenPage from '@assets/screen/upsellingScreenPage.png';
import walkUp from '@assets/screen/walkUp.png';
import paymentPage from '@assets/screen/paymentPage.png';
import landingPage from '@assets/screen/landingPage.png';
import preAuth from '@assets/screen/preAuth.png';
import ordersAndCard from '@assets/screen/ordersPage.png';
import orderPage from '@assets/screen/orderPage.png';
import addToBag from '@assets/screen/addToBag.png';
import modifyAndOrderAnother from '@assets/screen/modifyAndOrderAnother.png';
import updateBag from '@assets/screen/updateBag.png';
import mayWeSuggest from '@assets/screen/mayWeSuggest.png';
import selectStoreCheckOut from '@assets/screen/selectStoreCheckOut.png';
import ordersRetryAndSubmit from '@assets/screen/ordersRetryAndSubmit.png';
import priceCheckPOPIN from '@assets/screen/priceCheckPOPIN.png';
import areUSureDelete from '@assets/screen/areUSureDelete.png';
import coupons from '@assets/screen/coupons.png';
import giftCard from '@assets/screen/giftCard.png';
import paymentPOPUP from '@assets/screen/paymentPOPUP.png';
import paymentAmountScreen from '@assets/screen/paymentAmountScreen.png';
import paymentCharge from '@assets/screen/paymentCharge.png';
import orderWillBePlaced from '@assets/screen/orderWillBePlaced.png';
import receiptFirstPage from '@assets/screen/receiptFirstPage.png';
import receiptSecondPage from '@assets/screen/receiptSecondPage.png';
import payNowSuccessMsg from '@assets/screen/payNowSuccessMsg.png';
import curdSideWalkupPayLater from '@assets/screen/curdSideWalkupPayLater.png';
import oneLinePayLater from '@assets/screen/oneLinePayLater.png';
import ClickToPayLabel from '@assets/screen/ClickToPayLabel.png';
import addItemsToRefill from '@assets/screen/addItemsToRefill.png';
import addItemsToRefill2 from '@assets/screen/addItemsToRefill2.png';
import refillCartMsg from '@assets/screen/refillCartMsg.png';
import refillEmptyCartMSg from '@assets/screen/refillEmptyCartMSg.png';
import waitListFirstScreen from '@assets/screen/waitListFirstScreen.png';
import waitListThirdScreen from '@assets/screen/waitListThirdScreen.png';
import waitListSecondScreen from '@assets/screen/waitListSecondScreen.png';
import waitListPoPUPScreen from '@assets/screen/waitListPoPUPScreen.png';
import waitListSuccessScreen from '@assets/screen/waitListSuccessScreen.png';
import splitCheckFirstScreen from '@assets/screen/splitCheckFirstScreen.png';
import splitCheckSecondScreen from '@assets/screen/splitCheckSecondScreen.png';
import splitCheckThirdScreen from '@assets/screen/splitCheckThirdScreen.png';
import daisyChainFirstScreen from '@assets/screen/daisyChainFirstScreen.png';
import daisyChainSecondScreen from '@assets/screen/daisyChainSecondScreen.png';
import receiptPage from '@assets/screen/receiptPage _1.png';
import FooterMenus from '@assets/screen/footerMenus.png';
import daisyChain from '@assets/screen/daisyChain.png';
import waitList from '@assets/screen/waitList.png';
import HomePage from '@assets/screen/HomePage.png';
import splitCheck from '@assets/screen/splitCheck.png';
import loyalty from '@assets/screen/loyalty.png';
import tableGroupOrdering from '@assets/screen/tableGroupOrdering.png';
import submitOrder from '@assets/screen/submitOrder.png';
import immediateFeature from '@assets/screen/immediateFeature.png';
import signUpScreen from '@assets/screen/signUpScreen.png';
import payOnlyMode from '@assets/screen/payOnlyMode.png';
import listingPage from '@assets/screen/listingPage.png';
import myCheckPage from '@assets/screen/myCheckScreen.png';


export default {
  landingPage: {
    screenLabel: 'Landing Page',
    image: [landingPage],
    customLabels: [
      {
        defaultText: 'Spot Number',
        key: 'label.SPOT_NUMBER',
        type: 'label',
        value: 'SPOT_NUMBER',
        image: [curbside],
        screenName: ['curbside'],
      },
      {
        defaultText: 'What is your spot number?',
        key: 'customMessage.YOUR_SPOT_NUMBER',
        type: 'customMessage',
        value: 'YOUR_SPOT_NUMBER',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Enter your name below',
        key: 'customMessage.ENTER_NAME_BELOW',
        type: 'customMessage',
        value: 'ENTER_NAME_BELOW',
        image: [walkUp, online, onlinePickup],
        screenName: ['walkUp', 'online', 'onlinePickUp']
      },
      {
        defaultText: 'Name',
        key: 'label.NAME',
        type: 'label',
        value: 'NAME',
        image: [walkUp, online, onlinePickup, onlineDelivery],
        screenName: ['walkUp', 'online', 'onlinePickup', 'onlineDelivery']
      },
      {
        defaultText: 'Next',
        key: 'label.NEXT',
        type: 'label',
        value: 'NEXT',
        image: [walkUp, online, onlinePickup, onlineDelivery, upsellingScreenPage],
        screenName: ['walkUp', 'online', 'onlinePickup', 'onlineDelivery', 'upsellingScreenPage']
      },
      {
        defaultText: 'Orders are currently taking',
        key: 'customMessage.ORDER_COMPLETE_MSG',
        type: 'customMessage',
        value: 'ORDER_COMPLETE_MSG',
        image: [],
        screenName: []
      },
      {
        defaultText: 'minutes',
        key: 'customMessage.MINUTES',
        type: 'customMessage',
        value: 'MINUTES',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Welcome Message',
        key: 'customMessage.WELCOME_MSG',
        type: 'customMessage',
        value: 'WELCOME_MSG',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Pickup',
        key: 'label.PICKUP',
        type: 'label',
        value: 'PICKUP',
        image: [online, onlinePickup, onlineDelivery],
        screenName: ['online', 'onlinePickup', 'onlineDelivery']
      },
      {
        defaultText: 'Delivery',
        key: 'label.DELIVERY',
        type: 'label',
        value: 'DELIVERY',
        image: [online, onlinePickup, onlineDelivery],
        screenName: ['online', 'onlinePickup', 'onlineDelivery']
      },
      {
        defaultText: 'When would you like to pickup your order?',
        key: 'customMessage.PICKUP_YOUR_ORDER',
        type: 'customMessage',
        value: 'PICKUP_YOUR_ORDER',
        image: [online, onlinePickup],
        screenName: ['online', 'onlinePickUp']
      },
      {
        defaultText: 'When would you like your order delivered?',
        key: 'customMessage.DELIVER_YOUR_ORDER',
        type: 'customMessage',
        value: 'DELIVER_YOUR_ORDER',
        image: [onlineDelivery],
        screenName: ['onlineDelivery']
      },
      {
        defaultText: 'Select a restaurant you would like to order from',
        key: 'customMessage.SELECT_STORE_ORDER_FROM',
        type: 'customMessage',
        value: 'SELECT_STORE_ORDER_FROM',
        image: [onlineDelivery],
        screenName: ['onlineDelivery']
      },
      {
        defaultText: 'Pickup time not available for selected date',
        key: 'customMessage.PICKUP_TIME_NOT_AVAILABLE',
        type: 'customMessage',
        value: 'PICKUP_TIME_NOT_AVAILABLE',
      },
      {
        defaultText: 'Delivery time not available for selected date',
        key: 'customMessage.DELIVERY_TIME_NOT_AVAILABLE',
        type: 'customMessage',
        value: 'DELIVERY_TIME_NOT_AVAILABLE',
      },
      {
        defaultText: 'Vehicle Description',
        key: 'label.VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Search address',
        key: 'label.SEARCH_ADDRESS',
        type: 'label',
        value: 'SEARCH_ADDRESS',
      },
      {
        defaultText: 'Didn\'t receive ticket SMS? Click here to send',
        key: 'customMessage.STILL_NOT_RECIEVED_VOUCHER',
        type: 'customMessage',
        value: 'STILL_NOT_RECIEVED_VOUCHER',
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'customMessage.VOUCHER_ENTER_MOBILE_NUMBER',
        type: 'customMessage',
        value: 'VOUCHER_ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Voucher has been sent successfully to the registered mobile number.',
        key: 'customMessage.VOUCHER_SUCCESS_MESSAGE',
        type: 'customMessage',
        value: 'VOUCHER_SUCCESS_MESSAGE',
      },
      {
        defaultText: 'Please select different time slot and Voucher is sold out',
        key: 'customMessage.VOUCHER_TIME_ERROR',
        type: 'customMessage',
        value: 'VOUCHER_TIME_ERROR',
      },
      {
        defaultText: 'Use My Location',
        key: 'label.USE_MY_LOCATION',
        type: 'label',
        value: 'USE_MY_LOCATION',
      },
      {
        defaultText: 'Order cannot be placed because this section is not open. Please contact a server for assistance.',
        key: 'customMessage.EMP_TABLE_REASSIGNMENT_MSG',
        type: 'customMessage',
        value: 'EMP_TABLE_REASSIGNMENT_MSG',
      },
      {
        defaultText: 'Retry',
        key: 'label.TABLE_RETRY',
        type: 'label',
        value: 'TABLE_RETRY',
      },
      {
        defaultText: 'Thank you for updating your location. Your order will be delivered shortly.',
        key: 'customMessage.THANK_YOU_FOR_UPDATING_YOUR_LOCATION',
        type: 'customMessage',
        value: 'THANK_YOU_FOR_UPDATING_YOUR_LOCATION',
      },
      {
        defaultText: 'Please keep this tab open so we can continue locating you.',
        key: 'customMessage.PLEASE_KEEP_THIS_TAB_OPEN',
        type: 'customMessage',
        value: 'PLEASE_KEEP_THIS_TAB_OPEN',
      },
      {
        defaultText: 'Closing this tab will stop location tracking.',
        key: 'customMessage.CLOSING_THIS_TAB_WILL_STOP_TRACKING',
        type: 'customMessage',
        value: 'CLOSING_THIS_TAB_WILL_STOP_TRACKING',
      },
      {
        defaultText: 'Sorry, your location could not be transmitted to the restaurant. Please contact the restaurant directly.',
        key: 'customMessage.SORRY_YOUR_LOCATION_COULD_NOT_BE_TRANSMITTED',
        type: 'customMessage',
        value: 'SORRY_YOUR_LOCATION_COULD_NOT_BE_TRANSMITTED',
      },
      {
        defaultText: 'Provide a short description of your vehicle',
        key: 'customMessage.ENTER_CAR_DESCRIPTION',
        type: 'customMessage',
        value: 'ENTER_CAR_DESCRIPTION',
      },
      {
        defaultText: 'Please select an address:',
        key: 'label.PLEASE_SELECT_AN_ADDRESS',
        type: 'label',
        value: 'PLEASE_SELECT_AN_ADDRESS',
      },
      {
        defaultText: 'Apartment / Suite (optional)',
        key: 'label.DELIVERY_APARTMENT_NUMBER',
        type: 'label',
        value: 'DELIVERY_APARTMENT_NUMBER',
      },
      {
        defaultText: 'Order note (optional)',
        key: 'label.DELIVERY_ORDER_NOTE',
        type: 'label',
        value: 'DELIVERY_ORDER_NOTE',
      },
      {
        defaultText: 'Confirm',
        key: 'label.LOCATION_CONFIRM',
        type: 'label',
        value: 'LOCATION_CONFIRM',
      },
      {
        defaultText: 'Cancel',
        key: 'label.LOCATION_CANCEL',
        type: 'label',
        value: 'LOCATION_CANCEL',
      },
      {
        defaultText: 'Please confirm that you are at the location',
        key: 'customMessage.PLEASE_CONFIRM_LOCATION',
        type: 'customMessage',
        value: 'PLEASE_CONFIRM_LOCATION',
      },
      {
        defaultText: 'Your check-in request has been received.',
        key: 'customMessage.SPOT_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'SPOT_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'Please click the button when you are at the location.',
        key: 'customMessage.PLEASE_CLICK_BUTTON',
        type: 'customMessage',
        value: 'PLEASE_CLICK_BUTTON',
      },
      {
        defaultText: 'Vehicle Description (optional)',
        key: 'label.PICKUP_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'PICKUP_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Vehicle Description',
        key: 'label.PICKUP_VEHICLE_DESCRIPTION_TEXT',
        type: 'label',
        value: 'PICKUP_VEHICLE_DESCRIPTION_TEXT',
      },
    ]
  },
  loginScreen: {
    screenLabel: 'Login screens',
    image: [signUpScreen],
    customLabels: [
      {
        defaultText: 'You`ll need to verify your phone number before you can order or pay',
        key: 'customMessage.VERIFY_PHONE_NUMBER_MSG',
        type: 'customMessage',
        value: 'VERIFY_PHONE_NUMBER_MSG',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Verify Your Phone',
        key: 'label.VERIFY_YOUR_PHONE',
        type: 'label',
        value: 'VERIFY_YOUR_PHONE',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'OR',
        key: 'label.OR',
        type: 'label',
        value: 'OR',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Continue as Guest',
        key: 'label.CONTINUE_AS_GUEST',
        type: 'label',
        value: 'CONTINUE_AS_GUEST',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'You`re checking in at:',
        key: 'customMessage.YOUR_CHECKED_LOCATION',
        type: 'customMessage',
        value: 'YOUR_CHECKED_LOCATION',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Sign in with your phone number:',
        key: 'customMessage.SIGN_IN_PHONE_NUMBER',
        type: 'customMessage',
        value: 'SIGN_IN_PHONE_NUMBER',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'label.ENTER_MOBILE_NUMBER_LOGIN',
        type: 'label',
        value: 'ENTER_MOBILE_NUMBER_LOGIN',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Verify',
        key: 'label.VERIFY',
        type: 'label',
        value: 'VERIFY',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Please agree to terms of service to continue',
        key: 'customMessage.TERMS_CONDITION',
        type: 'customMessage',
        value: 'TERMS_CONDITION',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Sign in with a password',
        key: 'customMessage.LOGIN_WITH_PASSWORD',
        type: 'customMessage',
        value: 'LOGIN_WITH_PASSWORD',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Sign in to your account:',
        key: 'customMessage.SIGN_IN_TO_ACCOUNT',
        type: 'customMessage',
        value: 'SIGN_IN_TO_ACCOUNT',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Username',
        key: 'label.ENTER_USERNAME_EMAIL',
        type: 'label',
        value: 'ENTER_USERNAME_EMAIL',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Password',
        key: 'label.ENTER_PASSWORD',
        type: 'label',
        value: 'ENTER_PASSWORD',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'SIGN IN',
        key: 'label.LOGIN',
        type: 'label',
        value: 'LOGIN',
        image: [signUpScreen],
        screenName: []
      },
      // {
      //   defaultText: 'Please agree to terms of service to continue',
      //   key: 'customMessage.PLEASE_AGREE_TO_TERMS_OF_SERVICE',
      // },
      {
        defaultText: 'Forgot your password?',
        key: 'customMessage.FORGOT_YOUR_PASSWORD',
        type: 'customMessage',
        value: 'FORGOT_YOUR_PASSWORD',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Sign in with your phone number',
        key: 'customMessage.LOGIN_WITH_CODE',
        type: 'customMessage',
        value: 'LOGIN_WITH_CODE',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'By entering your mobile number you agree to receive a one time password and order status alerts via text message.Message and data rates may apply',
        key: 'customMessage.STANDARD_TEXT_RATES',
        type: 'customMessage',
        value: 'STANDARD_TEXT_RATES',
      },
      {
        defaultText: 'Enter your verification code:',
        key: 'customMessage.ENTER_VERIFICATION_CODE',
        type: 'customMessage',
        value: 'ENTER_VERIFICATION_CODE',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Verification code',
        key: 'label.ENTER_THE_OTP',
        type: 'label',
        value: 'ENTER_THE_OTP',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Continue',
        key: 'label.CONTINUE_BTN',
        type: 'label',
        value: 'CONTINUE_BTN',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Use a different phone number',
        key: 'customMessage.USE_DIFFERENT_NUMBER',
        type: 'customMessage',
        value: 'USE_DIFFERENT_NUMBER',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Enter your email address',
        key: 'label.ENTER_EMAIL_ADDRESS',
        type: 'label',
        value: 'ENTER_EMAIL_ADDRESS',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Get security code by Email',
        key: 'label.GET_EMAIL_OTP',
        type: 'label',
        value: 'GET_EMAIL_OTP',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Get security code by Text',
        key: 'label.GET_MOBILE_OTP',
        type: 'label',
        value: 'GET_MOBILE_OTP',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'Suddenly remembered your Password?',
        key: 'customMessage.SUDDENLY_REMEMBERED_YOUR_PASSWORD',
        type: 'customMessage',
        value: 'SUDDENLY_REMEMBERED_YOUR_PASSWORD',
        image: [signUpScreen],
        screenName: []
      },
      {
        defaultText: 'SMS verification is currently offline. Please enter the last four digits of the phone number to get a four hour access to the system',
        key: 'customMessage.SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE',
        type: 'customMessage',
        value: 'SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE',
      },
      {
        defaultText: 'SMS verification is currently offline. Please enter the last four digits of the phone number and get four hour access to the system',
        key: 'customMessage.SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_NO_TOAST',
        type: 'customMessage',
        value: 'SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_NO_TOAST',
      },   
      {
        defaultText: 'Enter last four of phone number',
        key: 'customMessage.ENTER_LAST_FOUR_OF_PHONE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_LAST_FOUR_OF_PHONE_NUMBER',
      },   
      {
        defaultText: 'Last four of phone number',
        key: 'customMessage.LAST_FOUR_OF_PHONE_NUMBER',
        type: 'customMessage',
        value: 'LAST_FOUR_OF_PHONE_NUMBER',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'customMessage.ENTER_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Enter email address to receive code',
        key: 'customMessage.ENTER_EMAIL_ADDRESS_TO_RECIEVE_CODE',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ADDRESS_TO_RECIEVE_CODE',
      },
      {
        defaultText: 'A verification code has been sent to your email address',
        key: 'customMessage.OTP_SENT_TO_EMAIL',
        type: 'customMessage',
        value: 'OTP_SENT_TO_EMAIL',
      },
      {
        defaultText: 'We are unable to send an SMS to this location as the network is offline. Please enter valid email address to receive your code. Please ensure that your connected to a wi-fi network',
        key: 'customMessage.SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_OFFLINE_MODE',
        type: 'customMessage',
        value: 'SMS_VERIFICATION_CURRENLTY_OFFLINE_MESSAGE_OFFLINE_MODE',
      },
      {
        defaultText: 'Use an email address',
        key: 'label.USE_EMAIL_ADRESS',
        type: 'label',
        value: 'USE_EMAIL_ADRESS',
      },
    ]
  },
  ordersScreen: {
    screenLabel: 'Orders & Cart',
    image: [ordersAndCard],
    customLabels: [
      {
        defaultText: 'Order',
        key: 'label.ORDER',
        type: 'label',
        value: 'ORDER',
        image: [orderPage],
        screenName: ['tableOrder_homePage', 'orderScreenTableOrder', 'upsellingScreenPage', 'checkout_cartInBag', 'moreScreen']
      },
      {
        defaultText: 'Add to Bag',
        key: 'label.ADD_TO_CART',
        type: 'label',
        value: 'ADD_TO_CART',
        image: [addToBag],
        screenName: ['itemDetailScreen', 'upsellingScreenPage']
      },
      {
        defaultText: 'Modify',
        key: 'label.MODIFY',
        type: 'label',
        value: 'MODIFY',
        image: [modifyAndOrderAnother],
        screenName: []
      },
      {
        defaultText: 'Order Another',
        key: 'label.ORDER_ANOTHER',
        type: 'label',
        value: 'ORDER_ANOTHER',
        image: [modifyAndOrderAnother],
        screenName: []
      },
      {
        defaultText: 'Update Bag',
        key: 'label.UPDATE_CART',
        type: 'label',
        value: 'UPDATE_CART',
        image: [updateBag],
        screenName: []
      },
      {
        defaultText: 'May we suggest?',
        key: 'customMessage.MAY_WE_SUGGEST',
        type: 'customMessage',
        value: 'MAY_WE_SUGGEST',
        image: [upsellingScreenPage],
        screenName: ['orderandcart']
      },
      {
        defaultText: 'Please select the store to checkout from?',
        key: 'customMessage.STORE_CHECK_OUT',
        type: 'customMessage',
        value: 'STORE_CHECK_OUT',
        image: [selectStoreCheckOut],
        screenName: ['storeCheckOut']
      },
      {
        defaultText: 'Pay Now',
        key: 'label.PAY_NOW',
        type: 'label',
        value: 'PAY_NOW',
        image: [checkout_cartInBag, payNowScreen, paymentScreen_applyCouponAndLoyalty],
        screenName: ['checkout_cartInBag', 'payNowScreen', 'paymentScreen_applyCouponAndLoyalty']
      },
      {
        defaultText: 'Pay Later',
        key: 'label.PAY_LATER',
        type: 'label',
        value: 'PAY_LATER',
        image: [checkout_cartInBag, payNowScreen, paymentScreen_applyCouponAndLoyalty],
        screenName: ['checkout_cartInBag', 'payNowScreen', 'paymentScreen_applyCouponAndLoyalty']
      },
      {
        defaultText: 'Retry Payment',
        key: 'label.RETRY_PAYMENT',
        type: 'label',
        value: 'RETRY_PAYMENT',
        image: [ordersRetryAndSubmit],
        screenName: []
      },
      {
        defaultText: 'Submit Order',
        key: 'label.SUBMIT_ORDER',
        type: 'label',
        value: 'SUBMIT_ORDER',
        image: [ordersRetryAndSubmit],
        screenName: []
      },
      {
        defaultText: 'Price Check Pop up Error Message',
        key: 'label.PRICE_CHECK_ERROR_MSG',
        type: 'label',
        value: 'PRICE_CHECK_ERROR_MSG',
        image: [priceCheckPOPIN],
        screenName: []
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.DELETE_ITEM_CONFIRMATION',
        type: 'customMessage',
        value: 'DELETE_ITEM_CONFIRMATION',
        image: [areUSureDelete],
        screenName: []
      },
      {
        defaultText: 'Submit Order',
        key: 'label.CART_BUTTON',
        type: 'label',
        value: 'CART_BUTTON',
        image: [submitOrder],
        screenName: []
      },
      {
        defaultText: 'Order Now',
        key: 'label.ORDER_NOW',
        type: 'label',
        value: 'ORDER_NOW',
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
      },
      {
        defaultText: 'Checkout',
        key: 'label.CHECKOUT',
        type: 'label',
        value: 'CHECKOUT',
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
      },
      {
        defaultText: 'Out of Stock',
        key: 'label.OUT_OF_STOCK',
        type: 'label',
        value: 'OUT_OF_STOCK',
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
      },
      {
        defaultText: 'Search',
        key: 'label.ITEM_SEARCH',
        type: 'label',
        value: 'ITEM_SEARCH',
      },
      {
        defaultText: 'Special Instructions',
        key: 'label.SPECIAL_INSTRUCTIONS',
        type: 'label',
        value: 'SPECIAL_INSTRUCTIONS',
      },
      {
        defaultText: 'Select Delivery Time',
        key: 'label.SELECT_DELIVERY_TIME',
        type: 'label',
        value: 'SELECT_DELIVERY_TIME',
      },
      {
        defaultText: 'Select Delivery Date',
        key: 'label.SELECT_DELIVERY_DATE',
        type: 'label',
        value: 'SELECT_DELIVERY_DATE',
      },
      {
        defaultText: 'Selection should be later than current date and time',
        key: 'customMessage.DATE_AND_TIME_ERROR',
        type: 'customMessage',
        value: 'DATE_AND_TIME_ERROR',
      },
      {
        defaultText: 'Select Pickup Time',
        key: 'label.SELECT_PICK_UP_TIME',
        type: 'label',
        value: 'SELECT_PICK_UP_TIME',
      },
      {
        defaultText: 'Select Pickup Date',
        key: 'label.SELECT_PICK_UP_DATE',
        type: 'label',
        value: 'SELECT_PICK_UP_DATE',
      },
      {
        defaultText: 'Yes',
        key: 'label.EDIT_ONL_ORDERS_YES',
        type: 'label',
        value: 'EDIT_ONL_ORDERS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.EDIT_ONL_ORDERS_NO',
        type: 'label',
        value: 'EDIT_ONL_ORDERS_NO',
      },
      {
        defaultText: 'OK',
        key: 'label.EDIT_ONL_ORDERS_OK',
        type: 'label',
        value: 'EDIT_ONL_ORDERS_OK',
      },
      {
        defaultText: 'Spot Number',
        key: 'label.EDIT_CU_ORDERS_SPOT_NUMBER',
        type: 'label',
        value: 'EDIT_CU_ORDERS_SPOT_NUMBER',
      },
      {
        defaultText: 'Name',
        key: 'label.EDIT_CU_ORDERS_NAME',
        type: 'label',
        value: 'EDIT_CU_ORDERS_NAME',
      },
      {
        defaultText: 'Pick Up to',
        key: 'label.PICK_UP_TO',
        type: 'label',
        value: 'PICK_UP_TO',
      },
      {
        defaultText: 'Optional',
        key: 'label.OPTIONAL',
        type: 'label',
        value: 'OPTIONAL',
      },
      {
        defaultText: 'Drive thru',
        key: 'label.DRIVE_THRU',
        type: 'label',
        value: 'DRIVE_THRU',
      },
      {
        defaultText: 'Coming soon',
        key: 'label.COMING_SOON',
        type: 'label',
        value: 'COMING_SOON',
      },
      {
        defaultText: 'View',
        key: 'label.VIEW',
        type: 'label',
        value: 'VIEW',
      },
      {
        defaultText: 'We’re sorry we are unable to collect payment online at the moment. Would you like to submit your order and pay at the restaurant?',
        key: 'customMessage.PRICE_CHECK_ERROR_MESSAGE',
        type: 'customMessage',
        value: 'PRICE_CHECK_ERROR_MESSAGE',
      },
      {
        defaultText: 'We’re sorry we are unable to process your order because the restaurant is offline. Please call the restaurant directly. Click the continue button to view your order',
        key: 'customMessage.STORE_OFFLINE_ERROR_MESSAGE',
        type: 'customMessage',
        value: 'STORE_OFFLINE_ERROR_MESSAGE',
      },
      {
        defaultText: 'Click to call',
        key: 'label.CLICK_TO_CALL',
        type: 'label',
        value: 'CLICK_TO_CALL',
      },
      {
        defaultText: 'Current Location',
        key: 'label.CURRENT_LOCATION',
        type: 'label',
        value: 'CURRENT_LOCATION',
      },
      {
        defaultText: 'Confirm Location',
        key: 'label.CONFIRM_LOCATION',
        type: 'label',
        value: 'CONFIRM_LOCATION',
      },
      {
        defaultText: 'Menu is currently not available.Please select a different menu or contact the restaurant',
        key: 'customMessage.MENU_NOT_AVAILABLE',
        type: 'customMessage',
        value: 'MENU_NOT_AVAILABLE',
      },
      {
        defaultText: 'Search item not found.Please select a different item or contact the restaurant',
        key: 'customMessage.ITEM_NOT_FOUND',
        type: 'customMessage',
        value: 'ITEM_NOT_FOUND',
      },
      {
        defaultText: 'Your cart is empty',
        key: 'customMessage.YOUR_CART_EMPTY',
        type: 'customMessage',
        value: 'YOUR_CART_EMPTY',
      },
      {
        defaultText: 'Go ahead and add something!',
        key: 'customMessage.ADD_SOMETHING',
        type: 'customMessage',
        value: 'ADD_SOMETHING',
      },
      {
        defaultText: 'Location',
        key: 'label.HEADER_LOCATION',
        type: 'label',
        value: 'HEADER_LOCATION',
      },
      {
        defaultText: 'Table',
        key: 'label.HEADER_TABLE',
        type: 'label',
        value: 'HEADER_TABLE',
      },
      {
        defaultText: 'Check #',
        key: 'label.HEADER_CHECK',
        type: 'label',
        value: 'HEADER_CHECK',
      },
      {
        defaultText: 'View Check',
        key: 'label.VIEW_CHECK',
        type: 'label',
        value: 'VIEW_CHECK',
      },
      {
        defaultText: 'No checks are available',
        key: 'customMessage.NO_CHECKS_AVAILABLE',
        type: 'customMessage',
        value: 'NO_CHECKS_AVAILABLE',
      },
      {
        defaultText: 'Pay My Check',
        key: 'label.PAY_MY_CHECK',
        type: 'label',
        value: 'PAY_MY_CHECK',
      },
      {
        defaultText: 'Remove',
        key: 'label.MODIFIER_REMOVE',
        type: 'label',
        value: 'MODIFIER_REMOVE',
      },
      {
        defaultText: 'Done',
        key: 'label.MODIFIER_DONE',
        type: 'label',
        value: 'MODIFIER_DONE',
      },
      {
        defaultText: 'Remaining Budget',
        key: 'label.BUDGET',
        type: 'label',
        value: 'BUDGET',
      },
      {
        defaultText: 'Cannot proceed further because voucher is not setup',
        key: 'customMessage.VOUCHER_ERROR_MESSAGE',
        type: 'customMessage',
        value: 'VOUCHER_ERROR_MESSAGE',
      },
      {
        defaultText: 'Please contact the restaurant to make any changes to your orders',
        key: 'customMessage.PLEASE_CONTACT_THE_RESTAURANT',
        type: 'customMessage',
        value: 'PLEASE_CONTACT_THE_RESTAURANT',
      },
      {
        defaultText: 'Cals',
        key: 'label.CALS',
        type: 'label',
        value: 'CALS',
      },
      {
        defaultText: `Cals Separator (eg: '-')`,
        key: 'label.CALS_SEPARATOR',
        type: 'label',
        value: 'CALS_SEPARATOR',
      },
      {
        defaultText: `Cals Separator Start (eg: '(')`,
        key: 'label.CALS_SEPARATOR_START',
        type: 'label',
        value: 'CALS_SEPARATOR_START',
      },
      {
        defaultText: `Cals Separator End (eg: ')')`,
        key: 'label.CALS_SEPARATOR_END',
        type: 'label',
        value: 'CALS_SEPARATOR_END',
      },
      {
        defaultText: 'From Cals',
        key: 'label.CALS_FROM',
        type: 'label',
        value: 'CALS_FROM',
      },
      {
        defaultText: 'Order contains items that require age verification and will be held. Do you want to send the other items anyway?',
        key: 'customMessage.AGE_VERIFICATION_CHECKING',
        type: 'customMessage',
        value: 'AGE_VERIFICATION_CHECKING',
      },
      {
        defaultText: 'Items held pending age verification',
        key: 'customMessage.ITEMS_HELD_PENDING_AGE_VERIFICATION',
        type: 'customMessage',
        value: 'ITEMS_HELD_PENDING_AGE_VERIFICATION',
      },
      {
        defaultText: 'Notify Server',
        key: 'label.NOTIFY_SERVER',
        type: 'label',
        value: 'NOTIFY_SERVER',
      },
      {
        defaultText: 'Edit Cart',
        key: 'label.AGE_RESTRICTED_EDIT_CART',
        type: 'label',
        value: 'AGE_RESTRICTED_EDIT_CART',
      },
      {
        defaultText: 'Continue',
        key: 'label.AGE_RESTRICTED_CONTINUE',
        type: 'label',
        value: 'AGE_RESTRICTED_CONTINUE',
      },
      {
        defaultText: 'Skip',
        key: 'label.PRE_SKIP',
        type: 'label',
        value: 'PRE_SKIP',
      },
      {
        defaultText: 'Order',
        key: 'label.PRE_ORDER',
        type: 'label',
        value: 'PRE_ORDER',
      },
      {
        defaultText: 'every',
        key: 'label.PRE_EVERY',
        type: 'label',
        value: 'PRE_EVERY',
      },
      {
        defaultText: 'minutes',
        key: 'label.PRE_MINUTES',
        type: 'label',
        value: 'PRE_MINUTES',
      },
      {
        defaultText: 'times',
        key: 'label.PRE_TIMES',
        type: 'label',
        value: 'PRE_TIMES',
      },
      {
        defaultText: 'Save time and pre-order - select time and number of rounds, or click skip to just order this round.',
        key: 'customMessage.PRE_ORDER_MESSAGE',
        type: 'customMessage',
        value: 'PRE_ORDER_MESSAGE',
      },
      {
        defaultText: 'Add this item to cart and then order every',
        key: 'customMessage.PRE_ADD_THIS_ITEM',
        type: 'customMessage',
        value: 'PRE_ADD_THIS_ITEM',
      },
      {
        defaultText: 'Add for',
        key: 'label.ADD_FOR',
        type: 'label',
        value: 'ADD_FOR',
      },
      {
        defaultText: 'My Order',
        key: 'label.MY_ORDER',
        type: 'label',
        value: 'MY_ORDER',
      },
      {
        defaultText: 'Date',
        key: 'label.DATE',
        type: 'label',
        value: 'DATE',
      },
      {
        defaultText: 'Order One Drink',
        key: 'label.PRE_ORDER_ORDER_ONE_DRINK',
        type: 'label',
        value: 'PRE_ORDER_ORDER_ONE_DRINK',
      },
      {
        defaultText: 'Order More Than One Round',
        key: 'label.PRE_ORDER_ORDER_MORE_THAN_ONE_DRINK',
        type: 'label',
        value: 'PRE_ORDER_ORDER_MORE_THAN_ONE_DRINK',
      },
      {
        defaultText:  'Only want one drink? Select "Order One Drink"',
        key: 'customMessage.PRE_ORDER_ONE_DRINK',
        type: 'customMessage',
        value: 'PRE_ORDER_ONE_DRINK',
      },
      {
        defaultText: 'Want more than one drink? You can now pre-order rounds. You\'ll get ONE to start, then select how MANY rounds and how OFTEN you\'d like them.',
        key: 'customMessage.PRE_ADD_MULTIPLE_DRINK',
        type: 'customMessage',
        value: 'PRE_ADD_MULTIPLE_DRINK',
      },
      {
        defaultText: 'MORE rounds delivered',
        key: 'customMessage.PRE_MORE_DRINKS_DELIVERED',
        type: 'customMessage',
        value: 'PRE_MORE_DRINKS_DELIVERED',
      },
      {
        defaultText: 'No Open check available to place order. Please contact your server to open a check or try again.',
        key: 'customMessage.NO_OPEN_CHECK_AVAILABLE',
        type: 'customMessage',
        value: 'NO_OPEN_CHECK_AVAILABLE',
      },
    ]
  },
  profileScreen: {
      screenLabel: 'Profile Page',
      image: [myAccountScreen],
      customLabels: [
        {
          defaultText: 'My Account',
          key: 'label.ACCOUNT',
          type: 'label',
          value: 'ACCOUNT',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'First Name',
          key: 'label.FIRST_NAME',
          type: 'label',
          value: 'FIRST_NAME',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Last Name',
          key: 'label.LAST_NAME',
          type: 'label',
          value: 'LAST_NAME',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Order Nickname',
          key: 'label.NICK_NAME',
          type: 'label',
          value: 'NICK_NAME',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'User Name',
          key: 'label.USER_NAME',
          type: 'label',
          value: 'USER_NAME',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Phone Number',
          key: 'label.PHONE_NUMBER',
          type: 'label',
          value: 'PHONE_NUMBER',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Email Address',
          key: 'label.EMAIL_ADDRESS_PROFILE',
          type: 'label',
          value: 'EMAIL_ADDRESS_PROFILE',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Check In',
          key: 'label.HOME',
          type: 'label',
          value: 'HOME',
          image: [myAccountScreen],
          screenName: []
        },      
        {
          defaultText: 'Offers & Promotions',
          key: 'label.OFFERS_PROMOTIONS',
          type: 'label',
          value: 'OFFERS_PROMOTIONS',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Payments',
          key: 'label.WALLET',
          type: 'label',
          value: 'WALLET',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'My saved card',
          key: 'label.MY_SAVED_CARD',
          type: 'label',
          value: 'MY_SAVED_CARD',
        },
        {
          defaultText: 'Use a saved card',
          key: 'label.USE_SAVED_CARD',
          type: 'label',
          value: 'USE_SAVED_CARD',
        },
        {
          defaultText: 'Exp',
          key: 'label.EXP',
          type: 'label',
          value: 'EXP',
        },
        {
          defaultText: 'Enter new card',
          key: 'label.ENTER_NEW_CARD',
          type: 'label',
          value: 'ENTER_NEW_CARD',
        },
        {
          defaultText: 'Card Name',
          key: 'label.CARD_NAME',
          type: 'label',
          value: 'CARD_NAME',
        },
        {
          defaultText: 'Change card',
          key: 'label.CHANGE_CARD',
          type: 'label',
          value: 'CHANGE_CARD',
        },
        {
          defaultText: 'Update card name',
          key: 'label.UPDATE_CARD_NAME',
          type: 'label',
          value: 'UPDATE_CARD_NAME',
        },
        {
          defaultText: 'Your wallet is empty',
          key: 'customMessage.WALLET_EMPTY',
          type: 'customMessage',
          value: 'WALLET_EMPTY',
        },
        {
          defaultText: 'Please save some card to wallet',
          key: 'customMessage.WALLET_PLEASE_ADD_CARD',
          type: 'customMessage',
          value: 'WALLET_PLEASE_ADD_CARD',
        },
        {
          defaultText: 'Please select a card',
          key: 'customMessage.PLEASE_SELECT_CARD',
          type: 'customMessage',
          value: 'PLEASE_SELECT_CARD',
        },
        {
          defaultText: 'Please add a card',
          key: 'customMessage.PLEASE_ADD_CARD',
          type: 'customMessage',
          value: 'PLEASE_ADD_CARD',
        },
        {
          defaultText: 'Enter the card name',
          key: 'customMessage.ENTER_CARD_NAME',
          type: 'customMessage',
          value: 'ENTER_CARD_NAME',
        },
        {
          defaultText: 'Are you sure want to delete?',
          key: 'customMessage.WANT_TO_DELETE',
          type: 'customMessage',
          value: 'WANT_TO_DELETE',
        },
        {
          defaultText: 'Name',
          key: 'label.LOYALTY_WALLET_NAME',
          type: 'label',
          value: 'LOYALTY_WALLET_NAME',
        },
        {
          defaultText: 'Update Name',
          key: 'label.LOYALTY_UPDATE_NAME',
          type: 'label',
          value: 'LOYALTY_UPDATE_NAME',
        },
        {
          defaultText: 'Save',
          key: 'label.ACC_WALLET_SAVE',
          type: 'label',
          value: 'ACC_WALLET_SAVE',
        },
        {
          defaultText: 'Cancel',
          key: 'label.ACC_WALLET_CANCEL',
          type: 'label',
          value: 'ACC_WALLET_CANCEL',
        },
        {
          defaultText: 'Loyalty Accounts',
          key: 'label.LOYALTY_ACCOUNTS',
          type: 'label',
          value: 'LOYALTY_ACCOUNTS',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'No results found',
          key: 'customMessage.NO_RESULTS_FOUND',
          type: 'customMessage',
          value: 'NO_RESULTS_FOUND',
        },
        {
          defaultText: 'Past Orders',
          key: 'label.ORDER_HISTORY',
          type: 'label',
          value: 'ORDER_HISTORY',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Order History',
          key: 'label.ORDER_HISTORY_LABEL',
          type: 'label',
          value: 'ORDER_HISTORY_LABEL',
        },
        {
          defaultText: 'At Current Location',
          key: 'label.AT_CURRENT_LOCATIONS',
          type: 'label',
          value: 'AT_CURRENT_LOCATIONS',
        },
        {
          defaultText: 'At All Locations',
          key: 'label.AT_ALL_LOCATIONS',
          type: 'label',
          value: 'AT_ALL_LOCATIONS',
        },
        {
          defaultText: 'Total',
          key: 'label.PAST_ORDER_TOTAL',
          type: 'label',
          value: 'PAST_ORDER_TOTAL',
        },
        {
          defaultText: 'SubTotal',
          key: 'label.PAST_ORDER_SUBTOTAL',
          type: 'label',
          value: 'PAST_ORDER_SUBTOTAL',
        },
        {
          defaultText: 'Tip',
          key: 'label.PAST_ORDER_TIP',
          type: 'label',
          value: 'PAST_ORDER_TIP',
        },
        {
          defaultText: 'Tax',
          key: 'label.PAST_ORDER_TAX',
          type: 'label',
          value: 'PAST_ORDER_TAX',
        },
        {
          defaultText: 'You haven\'t placed any orders yet',
          key: 'customMessage.NO_ORDER_HISTORY',
          type: 'customMessage',
          value: 'NO_ORDER_HISTORY',
        },
        {
          defaultText: 'Address Book',
          key: 'label.WALLET_ADDRESS',
          type: 'label',
          value: 'WALLET_ADDRESS',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Name',
          key: 'label.WALLET_ADDRESS_NAME',
          type: 'label',
          value: 'WALLET_ADDRESS_NAME',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Update',
          key: 'label.WALLET_ADDRESS_UPDATE',
          type: 'label',
          value: 'WALLET_ADDRESS_UPDATE',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Add new card',
          key: 'label.WALLET_ADD_NEW_CARD',
          type: 'label',
          value: 'WALLET_ADD_NEW_CARD',
        },
        {
          defaultText: 'Save',
          key: 'label.WALLET_ADDRESS_SAVE',
          type: 'label',
          value: 'WALLET_ADDRESS_SAVE',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Sign Out',
          key: 'label.SIGN_OUT',
          type: 'label',
          value: 'SIGN_OUT',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'My Data',
          key: 'label.MY_DATA',
          type: 'label',
          value: 'MY_DATA',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Profile',
          key: 'label.PROFILE',
          type: 'label',
          value: 'PROFILE',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Email / SMS Preferences',
          key: 'label.PREFERENCES',
          type: 'label',
          value: 'PREFERENCES',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Log Out',
          key: 'label.LOGOUT',
          type: 'label',
          value: 'LOGOUT',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Subscription',
          key: 'label.SUBSCRIPTION',
          type: 'label',
          value: 'SUBSCRIPTION',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'No addresses found',
          key: 'customMessage.NO_ADDRESS_FOUND',
          type: 'customMessage',
          value: 'NO_ADDRESS_FOUND',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Save changes',
          key: 'label.SAVE_CHANGES',
          type: 'label',
          value: 'SAVE_CHANGES',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Expires on',
          key: 'label.EXPIRES_ON',
          type: 'label',
          value: 'EXPIRES_ON',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Edit Your Profile',
          key: 'customMessage.EDIT_PROFILE',
          type: 'customMessage',
          value: 'EDIT_PROFILE',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Change Your Password',
          key: 'customMessage.CHANGE_YOUR_PASSWORD',
          type: 'customMessage',
          value: 'CHANGE_YOUR_PASSWORD',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Your password must meet the following',
          key: 'customMessage.PASSWORD_MUST_MEET_THE_FOLLOWING',
          type: 'customMessage',
          value: 'PASSWORD_MUST_MEET_THE_FOLLOWING',
          image: [myAccountScreen],
          screenName: []
        },
        {
          defaultText: 'Current Password',
          key: 'label.CURRENT_PASSWORD',
          type: 'label',
          value: 'CURRENT_PASSWORD',
        },
        {
          defaultText: 'New Password',
          key: 'label.NEW_PASSWORD',
          type: 'label',
          value: 'NEW_PASSWORD',
        },
        {
          defaultText: 'Confirm Password',
          key: 'label.CONFIRM_PASSWORD',
          type: 'label',
          value: 'CONFIRM_PASSWORD',
        },
        {
          defaultText: '(temporary hold)',
          key: 'label.TEMPORARY_HOLD',
          type: 'label',
          value: 'TEMPORARY_HOLD',
        },
        {
          defaultText: 'Save',
          key: 'label.OPTIN_SAVE_CHANGES',
          type: 'label',
          value: 'OPTIN_SAVE_CHANGES',
        },
        {
          defaultText: 'Opt in to receive emails',
          key: 'label.OPT_IN_RECIEVE_EMAIL',
          type: 'label',
          value: 'OPT_IN_RECIEVE_EMAIL',
        },
        {
          defaultText: 'Opt in to receive SMS',
          key: 'label.OPT_IN_RECIEVE_SMS',
          type: 'label',
          value: 'OPT_IN_RECIEVE_SMS',
        },
        {
          defaultText: 'Skip Opt In',
          key: 'label.SKIP_OPT_IN',
          type: 'label',
          value: 'SKIP_OPT_IN',
        },
        {
          defaultText: 'Opt in to recieve communication',
          key: 'customMessage.OPT_INTO_RECIEVE_COMMUNICATION',
          type: 'customMessage',
          value: 'OPT_INTO_RECIEVE_COMMUNICATION',
        },
        {
          defaultText: 'My Offers',
          key: 'label.ACCOUNT_VIEW_OFFER',
          type: 'label',
          value: 'ACCOUNT_VIEW_OFFER',
        },       
      ]
    },
  PaymentPage: {
    screenLabel: 'Payment Page',
    image: [paymentPage],
    customLabels: [
      {
        defaultText: 'Coupons',
        key: 'label.COUPONS',
        type: 'label',
        value: 'COUPONS',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Add a tip',
        key: 'label.ADD_A_TIP',
        type: 'label',
        value: 'ADD_A_TIP',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'No Tip',
        key: 'label.NO_TIP',
        type: 'label',
        value: 'NO_TIP',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Other',
        key: 'label.OTHER',
        type: 'label',
        value: 'OTHER',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Total Amount',
        key: 'label.TOTAL_AMOUNT',
        type: 'label',
        value: 'TOTAL_AMOUNT',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Sub Total',
        key: 'label.SUB_TOTAL',
        type: 'label',
        value: 'SUB_TOTAL',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'TAX',
        key: 'label.TAX',
        type: 'label',
        value: 'TAX',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'TIP',
        key: 'label.TIP',
        type: 'label',
        value: 'TIP',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Service Charge',
        key: 'label.SERVICE_CHARGE',
        type: 'label',
        value: 'SERVICE_CHARGE',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Additional Charge',
        key: 'label.ADDITIONAL_CHARGE',
        type: 'label',
        value: 'ADDITIONAL_CHARGE',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Payments',
        key: 'label.PAYMENTS',
        type: 'label',
        value: 'PAYMENTS',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Due',
        key: 'label.DUE',
        type: 'label',
        value: 'DUE',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Total',
        key: 'label.PAYMENT_TOTAL',
        type: 'label',
        value: 'PAYMENT_TOTAL',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Total Due',
        key: 'label.TOTAL_DUE',
        type: 'label',
        value: 'TOTAL_DUE',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'Receipt',
        key: 'label.RECIEPT',
        type: 'label',
        value: 'RECIEPT',
        image: [coupons],
        screenName: ['coupons']
      },
      {
        defaultText: 'No',
        key: 'label.NO',
        type: 'label',
        value: 'NO',
        image: [payNowScreen, paymentScreen_applyCouponAndLoyalty],
        screenName: ['payNowScreen', 'paymentScreen_applyCouponAndLoyalty']
      },
      {
        defaultText: 'Yes',
        key: 'label.YES',
        type: 'label',
        value: 'YES',
        image: [payNowScreen, paymentScreen_applyCouponAndLoyalty],
        screenName: ['payNowScreen', 'paymentScreen_applyCouponAndLoyalty']
      },
      {
        defaultText: 'Confirm',
        key: 'label.CONFIRM',
        type: 'label',
        value: 'CONFIRM',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Gift Card',
        key: 'label.GIFT_CARD',
        type: 'label',
        value: 'GIFT_CARD',
        image: [giftCard],
        screenName: ['giftCard']
      },
      {
        defaultText: 'Percent',
        key: 'label.PERCENT',
        type: 'label',
        value: 'PERCENT',
        image: [paymentPOPUP],
        screenName: ['paymentPOPUP']
      },
      {
        defaultText: 'Amount',
        key: 'label.AMOUNT',
        type: 'label',
        value: 'AMOUNT',
        image: [paymentPOPUP],
        screenName: ['paymentPOPUP']
      },
      {
        defaultText: 'Tip this percentage of the subtotal:',
        key: 'customMessage.TIPS_PERCENTAGE_MSG',
        type: 'customMessage',
        value: 'TIPS_PERCENTAGE_MSG',
        image: [paymentPOPUP],
        screenName: ['paymentPOPUP']
      },
      {
        defaultText: 'Enter percentage',
        key: 'label.ENTER_PERCENTAGE',
        type: 'label',
        value: 'ENTER_PERCENTAGE',
        image: [paymentPOPUP],
        screenName: [paymentPOPUP]
      },
      {
        defaultText: 'Including tip,pay a total of',
        key: 'label.INCLUDING_TIP_NEW_TOTAL',
        type: 'label',
        value: 'INCLUDING_TIP_NEW_TOTAL',
        image: [paymentPOPUP],
        screenName: ['paymentPOPUP']
      },
      {
        defaultText: 'Including tip, make the total',
        key: 'customMessage.TOTAL_TIPS_MSG',
        type: 'customMessage',
        value: 'TOTAL_TIPS_MSG',
        image: [paymentPOPUP],
        screenName: ['paymentPOPUP']
      },
      {
        defaultText: 'Add tip',
        key: 'label.ADD_TIP',
        type: 'label',
        value: 'ADD_TIP',
      },
      {
        defaultText: 'Cancel',
        key: 'label.CANCEL',
        type: 'label',
        value: 'CANCEL',
        image: [paymentPOPUP],
        screenName: [paymentPOPUP]
      },
      {
        defaultText: 'Tip this amount',
        key: 'label.TIP_THIS_AMOUNT',
        type: 'label',
        value: 'TIP_THIS_AMOUNT',
        image: [paymentAmountScreen],
        screenName: ['paymentAmountScreen']
      },
      {
        defaultText: 'Enter Tip Amount',
        key: 'label.ENTER_TIP_AMOUNT',
        type: 'label',
        value: 'ENTER_TIP_AMOUNT',
        image: [paymentAmountScreen],
        screenName: [paymentAmountScreen]
      },
      {
        defaultText: 'Deliver To',
        key: 'label.CHECKOUT_DELIVER_TO',
        type: 'label',
        value: 'CHECKOUT_DELIVER_TO',
      },
      {
        defaultText: 'Spot Number',
        key: 'label.CHECKOUT_SPOT_NUMBER',
        type: 'label',
        value: 'CHECKOUT_SPOT_NUMBER',
      },
      {
        defaultText: 'Add Card',
        key: 'label.ADD_CARD',
        type: 'label',
        value: 'ADD_CARD',
        image: [paymentAmountScreen],
        screenName: [paymentAmountScreen]
      },
      {
        defaultText: 'Remove Card',
        key: 'label.REMOVE_CARD',
        type: 'label',
        value: 'REMOVE_CARD',
        image: [paymentAmountScreen],
        screenName: [paymentAmountScreen]
      },
      {
        defaultText: 'If you proceed,your card will be charged',
        key: 'label.CARD_CHARGED',
        type: 'label',
        value: 'CARD_CHARGED',
        image: [paymentCharge],
        screenName: ['paymentCharge']
      },
      {
        defaultText: 'If you proceed,your order will be placed',
        key: 'label.PROCEED_ORDER_PLACED',
        type: 'label',
        value: 'PROCEED_ORDER_PLACED',
        image: [orderWillBePlaced],
        screenName: ['orderWillBePlaced']
      },
      {
        defaultText: 'An automatic Service Charge has been added to you bill. 100% of our Service Charge benefits our team. To mantain a safe and contactless environment, we do not accept tips or gratuities. Thank you!',
        key: 'customMessage.AUTOMATIC_SERVICE_CHARGE_ADDED',
        type: 'customMessage',
        value: 'AUTOMATIC_SERVICE_CHARGE_ADDED',
      },
      {
        defaultText: 'Please select a tip to complete payment',
        key: 'customMessage.TIP_SELECTION_REQUIRED_MESSAGE',
        type: 'customMessage',
        value: 'TIP_SELECTION_REQUIRED_MESSAGE',
      },
      {
        defaultText: 'Additional Tip',
        key: 'label.ADDITIONAL_TIP',
        type: 'label',
        value: 'ADDITIONAL_TIP',
      },
      {
        defaultText: 'Add a gift card',
        key: 'label.ADD_GIFT_CARD',
        type: 'label',
        value: 'ADD_GIFT_CARD',
      },
      {
        defaultText: 'Enter a valid percent or zero',
        key: 'customMessage.TIP_INVALID_PERCETAGE_AMOUNT',
        type: 'customMessage',
        value: 'TIP_INVALID_PERCETAGE_AMOUNT',
      },
      {
        defaultText: 'Enter a valid amount or zero',
        key: 'customMessage.TIP_INVALID_AMOUNT',
        type: 'customMessage',
        value: 'TIP_INVALID_AMOUNT',
      },
      {
        defaultText: 'Receipt',
        key: 'label.VENUE_RECIEPT',
        type: 'label',
        value: 'VENUE_RECIEPT',
      },
      {
        defaultText: 'If you proceed, your order will be placed.',
        key: 'customMessage.ORDER_CONFIRMATION',
        type: 'customMessage',
        value: 'ORDER_CONFIRMATION',
      },
      {
        defaultText: 'Your order is being processed and may take a moment. Please do not close this window or click the back or refresh button on your browser',
        key: 'customMessage.ORDER_LOADING_MESSAGE',
        type: 'customMessage',
        value: 'ORDER_LOADING_MESSAGE',
      },
      {
        defaultText: 'Check has been modified on the POS. Please click to refresh',
        key: 'customMessage.CHECK_MODIFIED',
        type: 'customMessage',
        value: 'CHECK_MODIFIED',
      },
      {
        defaultText: 'Refresh',
        key: 'label.REFRESH',
        type: 'label',
        value: 'REFRESH',
      },
      {
        defaultText: 'Add credit card',
        key: 'label.ADD_CREDIT_CARD',
        type: 'label',
        value: 'ADD_CREDIT_CARD',
      },
      {
        defaultText: 'Hold with',
        key: 'label.HOLD_WITH',
        type: 'label',
        value: 'HOLD_WITH',
      },
      {
        defaultText: 'CLICK TO PAY',
        key: 'label.CLICK_TO_PAY',
        type: 'label',
        value: 'CLICK_TO_PAY',
      },
      {
        defaultText: 'Remove Coupon',
        key: 'label.REMOVE_COUPON',
        type: 'label',
        value: 'REMOVE_COUPON',
      },
      {
        defaultText: 'Apply Coupon',
        key: 'label.APPLY_COUPON',
        type: 'label',
        value: 'APPLY_COUPON',
      },
      {
        defaultText: 'Apply Offer',
        key: 'label.APPLY_COUPON_OFFER',
        type: 'label',
        value: 'APPLY_COUPON_OFFER',
      },
      {
        defaultText: 'Skip Offer',
        key: 'label.SKIP_COUPON_OFFER',
        type: 'label',
        value: 'SKIP_COUPON_OFFER',
      },
      {
        defaultText: 'Select offer to apply',
        key: 'label.PAYMENT_VIEW_OFFER',
        type: 'label',
        value: 'PAYMENT_VIEW_OFFER',
      },
      {
        defaultText: 'Remove Offers',
        key: 'label.REMOVE_OFFER',
        type: 'label',
        value: 'REMOVE_OFFER',
      },
      {
        defaultText: 'The selected offer is valid only with PayPal payments.If you want to pay a different way,please remove the offer and try again',
        key: 'customMessage.REMOVE_OFFER_MESSAGE',
        type: 'customMessage',
        value: 'REMOVE_OFFER_MESSAGE',
      },
      {
        defaultText: 'Are you sure you want to cancel your order?',
        key: 'customMessage.CANCEL_ORDER_MESSAGE',
        type: 'customMessage',
        value: 'CANCEL_ORDER_MESSAGE',
      },
      {
        defaultText: 'Congratulations -you have qualified for this offer',
        key: 'customMessage.ELIGIBLE_OFFER_MESSAGE',
        type: 'customMessage',
        value: 'ELIGIBLE_OFFER_MESSAGE',
      },
      {
        defaultText: 'Add to your bag to qualify for this offer',
        key: 'customMessage.ADD_TO_BAG_OFFER_MESSAGE',
        type: 'customMessage',
        value: 'ADD_TO_BAG_OFFER_MESSAGE',
      },
      {
        defaultText: 'Check balance',
        key: 'label.GC_BALANCE',
        type: 'label',
        value: 'GC_BALANCE',
      },
      {
        defaultText: 'Balance Amount',
        key: 'label.GC_BALANCE_AMT',
        type: 'label',
        value: 'GC_BALANCE_AMT',
      },
      {
        defaultText: 'Redeemed Amount',
        key: 'label.GC_REDEEM_AMT',
        type: 'label',
        value: 'GC_REDEEM_AMT',
      },
      {
        defaultText: 'Total Due',
        key: 'label.GC_TOTAL_DUE',
        type: 'label',
        value: 'GC_TOTAL_DUE',
      },
      {
        defaultText: 'Amount to apply',
        key: 'label.GC_AMT_APPLY',
        type: 'label',
        value: 'GC_AMT_APPLY',
      },
      {
        defaultText: 'Redeem',
        key: 'label.GC_REDEEM',
        type: 'label',
        value: 'GC_REDEEM',
      },
      {
        defaultText: 'Enter gift card number',
        key: 'customMessage.GC_NUMBER',
        type: 'customMessage',
        value: 'GC_NUMBER',
      },
      {
        defaultText: 'Enter security code',
        key: 'customMessage.GC_SECURITY_CODE',
        type: 'customMessage',
        value: 'GC_SECURITY_CODE',
      },
      {
        defaultText: 'Loyalty & Offers',
        key: 'label.LOYALTY_AND_OFFERS',
        type: 'label',
        value: 'LOYALTY_AND_OFFERS',
      },
      {
        defaultText: 'Edit a gift card',
        key: 'label.EDIT_GIFT_CARD',
        type: 'label',
        value: 'EDIT_GIFT_CARD',
      },
      {
        defaultText: 'Edit Loyalty & Offers',
        key: 'label.EDIT_LOYALTY_AND_OFFERS',
        type: 'label',
        value: 'EDIT_LOYALTY_AND_OFFERS',
      },
      {
        defaultText: 'Charity',
        key: 'label.CHARITY',
        type: 'label',
        value: 'CHARITY',
      },
      {
        defaultText: 'Enter Account Number',
        key: 'customMessage.ENTER_ACCOUNT_NUMBER',
        type: 'customMessage',
        value: 'ENTER_ACCOUNT_NUMBER',
      },
      {
        defaultText: 'Submit',
        key: 'label.LOOKUP_SUBMIT',
        type: 'label',
        value: 'LOOKUP_SUBMIT',
      },
      {
        defaultText: 'Login or Register for Rewards',
        key: 'label.LOYALTY_LOGIN',
        type: 'label',
        value: 'LOYALTY_LOGIN',
      },
      {
        defaultText: 'Offers',
        key: 'label.OFFERS',
        type: 'label',
        value: 'OFFERS',
      },
      {
        defaultText: 'COMPLETE PAYMENT',
        key: 'label.COMPLETE_PAYMENT',
        type: 'label',
        value: 'COMPLETE_PAYMENT',
      },
      {
        defaultText: 'Offers cannot be applied to this check because the check has been split by persons',
        key: 'customMessage.OFFERS_CANNOT_BE_APPLIED',
        type: 'customMessage',
        value: 'OFFERS_CANNOT_BE_APPLIED',
      },
      {
        defaultText: 'If check is split by persons or amount, then no guest in your party can apply offers to this check. Please request the server to split the check if anybody in your party want to redeem an offer. Do you want to continue with this form of payment?',
        key: 'customMessage.SPLIT_BY_PERSON_POPUP_MSG',
        type: 'customMessage',
        value: 'SPLIT_BY_PERSON_POPUP_MSG',
      },
      {
        defaultText: 'State',
        key: 'label.CARD_STATE',
        type: 'label',
        value: 'CARD_STATE',
      },
      {
        defaultText: 'Address1',
        key: 'label.CARD_ADDRESS1',
        type: 'label',
        value: 'CARD_ADDRESS1',
      },
      {
        defaultText: 'Address2',
        key: 'label.CARD_ADDRESS2',
        type: 'label',
        value: 'CARD_ADDRESS2',
      },
      {
        defaultText: 'City',
        key: 'label.CARD_CITY',
        type: 'label',
        value: 'CARD_CITY',
      },
      {
        defaultText: 'Payment could not be processed - please contact a server for assistance',
        key: 'customMessage.PAYMENT_REQUIRES_SERVER_ERROR',
        type: 'customMessage',
        value: 'PAYMENT_REQUIRES_SERVER_ERROR',
      },
      {
        defaultText: 'CALL SERVER',
        key: 'label.CALL_SERVER',
        type: 'label',
        value: 'CALL_SERVER',
      },
      {
        defaultText: 'Pay by stage',
        key: 'label.PAY_BY_STAGE',
        type: 'label',
        value: 'PAY_BY_STAGE',
      },
      {
        defaultText:'Split',
        key: 'label.STAGE',
        type: 'label',
        value: 'STAGE',
      },
      {
        defaultText: 'Select a split',
        key: 'customMessage.PAY_SELECTED_STAGES',
        type: 'customMessage',
        value: 'PAY_SELECTED_STAGES',
      },
      {
        defaultText: 'Gratuity',
        key: 'customMessage.AUTO_GRATUITY_MESSAGE',
        type: 'customMessage',
        value: 'AUTO_GRATUITY_MESSAGE',
      },
      {
        defaultText: 'Unable to place a hold on this card - please try again or use a different card [Payment could not be processed - please contact a server for assistance]',
        key: 'customMessage.PAYMENT_HOLD_SERVER_ERROR',
        type: 'customMessage',
        value: 'PAYMENT_HOLD_SERVER_ERROR',
      },
      {
        defaultText: '',
        key: 'customMessage.TIP_DISCLAIMER',
        type: 'customMessage',
        value: 'TIP_DISCLAIMER',
      },
      {
        defaultText:'Pay with Cabbage',
        key: 'label.CABBAGE_PAY_BTN',
        type: 'label',
        value: 'CABBAGE_PAY_BTN',
      },
      {
        defaultText: 'Are you sure, You want to delete this Offer?',
        key: 'customMessage.OFFER_WANT_TO_DELETE',
        type: 'customMessage',
        value: 'OFFER_WANT_TO_DELETE',
      },
      {
        defaultText: 'Are you sure, You want to delete Loyalty?',
        key: 'customMessage.LOYALTY_WANT_TO_DELETE',
        type: 'customMessage',
        value: 'LOYALTY_WANT_TO_DELETE',
      },
      {
        defaultText: 'Are you sure, You want to delete gift card?',
        key: 'customMessage.GC_DELETE',
        type: 'customMessage',
        value: 'GC_DELETE',
      },
      {
        defaultText:  'Are you sure, You want to delete Coupon?',
        key: 'customMessage.COUPON_DELETE',
        type: 'customMessage',
        value: 'COUPON_DELETE',
      },
      {
        defaultText:  'Enter Postal Code',
        key: 'customMessage.GP_EMP_POSTAL_CODE',
        type: 'customMessage',
        value: 'GP_EMP_POSTAL_CODE',
      },
      {
        defaultText:  'Invalid Postal Code',
        key: 'customMessage.GP_INVALID_POSTAL_CODE',
        type: 'customMessage',
        value: 'GP_INVALID_POSTAL_CODE',
      },
      {
        defaultText:  'Are you sure, You want to delete Charity?',
        key: 'customMessage.CHARITY_DELETE',
        type: 'customMessage',
        value: 'CHARITY_DELETE',
      },
      {
        defaultText: 'enter a valid amount',
        key: 'customMessage.TIP_INVALID_TOTAL_AMOUNT',
        type: 'customMessage',
        value: 'TIP_INVALID_TOTAL_AMOUNT',
      },
      {
        defaultText: 'Use tip to round up total',
        key: 'label.TIP_ROUNDUP',
        type: 'label',
        value: 'TIP_ROUNDUP',
      },
      {
        defaultText: 'Skip Gift Card',
        key: 'label.SKIP_GIFTCARD',
        type: 'label',
        value: 'SKIP_GIFTCARD',
      },
      {
        defaultText: 'Apply Gift Card',
        key: 'label.APPLY_GIFTCARD',
        type: 'label',
        value: 'APPLY_GIFTCARD',
      },
      {
        defaultText: 'Please enter Valid amount',
        key: 'customMessage.CHARITY_VALID_AMOUNT',
        type: 'customMessage',
        value: 'CHARITY_VALID_AMOUNT',
      },
      {
        defaultText: 'Coupon',
        key: 'label.COUPON',
        type: 'label',
        value: 'COUPON',
      },
      {
        defaultText: 'Code',
        key: 'label.CODE',
        type: 'label',
        value: 'CODE',
      },
      {
        defaultText: 'Ok',
        key: 'label.OK',
        type: 'label',
        value: 'OK',
      },
      {
        defaultText: 'How would you like to Signup your account?',
        key: 'customMessage.LOYALTY_LOOKUP_JOIN_NOW',
        type: 'customMessage',
        value: 'LOYALTY_LOOKUP_JOIN_NOW',
      },
      {
        defaultText: 'Opt In To SMS marketing',
        key: 'customMessage.CHOOSE_SMS_OPTIN',
        type: 'customMessage',
        value: 'CHOOSE_SMS_OPTIN',
      },
      {
        defaultText: 'Opt In To Email marketing',
        key: 'customMessage.CHOOSE_EMAIL_OPTION',
        type: 'customMessage',
        value: 'CHOOSE_EMAIL_OPTION',
      },
      {
        defaultText: 'Please enter valid email address',
        key: 'customMessage.OPT_IN_VALID_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'OPT_IN_VALID_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Please enter valid phone number',
        key: 'customMessage.OPT_IN_VALID_PHONE_NUMBER',
        type: 'customMessage',
        value: 'OPT_IN_VALID_PHONE_NUMBER',
      },
      {
        defaultText: 'Phone number must be required',
        key: 'customMessage.OPT_IN_PHONE_REQUIRED',
        type: 'customMessage',
        value: 'OPT_IN_PHONE_REQUIRED',
      },
      {
        defaultText: 'Email address must be required',
        key: 'customMessage.OPT_IN_EMAIL_REQUIRED',
        type: 'customMessage',
        value: 'OPT_IN_EMAIL_REQUIRED',
      },
      {
        defaultText: 'Add Utensils?',
        key: 'label.ADD_UTENSILS',
        type: 'label',
        value: 'ADD_UTENSILS',
      },
      {
        defaultText: 'Update Account',
        key: 'label.AMAZON_PAY_UPDATE_ACC',
        type: 'label',
        value: 'AMAZON_PAY_UPDATE_ACC',
      },
    ]
  },
  PaymentSuccessPage: {
    screenLabel: 'Payment Success Page',
    image: [paymentPage],
    customLabels: [
      {
        defaultText: 'Your order has been placed.',
        key: 'customMessage.YOUR_ORDER_HAS_BEEN_PLACED',
        type: 'customMessage',
        value: 'YOUR_ORDER_HAS_BEEN_PLACED',
      },
      {
        defaultText: 'Payment has been received. Your order should be out shortly',
        key: 'customMessage.CURB_WALK_CONFIRMATION_MESSAGE_ORDER_PAID',
        type: 'customMessage',
        value: 'CURB_WALK_CONFIRMATION_MESSAGE_ORDER_PAID',
      },
      {
        defaultText: 'Your order has been received.A team member will be with you shortly to collect payment.',
        key: 'customMessage.CURB_WALK_CONFIRMATION_MESSAGE_ORDER_NOT_PAID',
        type: 'customMessage',
        value: 'CURB_WALK_CONFIRMATION_MESSAGE_ORDER_NOT_PAID',
      },
      {
        defaultText: 'Your order has been received.You will receive a text message with a link to check-in when you arrive at the location',
        key: 'customMessage.ONLINE_ORDER_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'ONLINE_ORDER_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'Your order has been received.You will receive a text message with a confirmation link.',
        key: 'customMessage.ONLINE_ORDER_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'ONLINE_ORDER_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'Your order has been received.You will receive a text message with a confirmation link',
        key: 'customMessage.ONLINE_ORDER_NOT_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'ONLINE_ORDER_NOT_PAID_SUPRESSONLINEORDERCHECKIN_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'Your order will be delivered from the following locations',
        key: 'customMessage.DELIVERY_CONSMENU_CONF_MESSAGE',
        type: 'customMessage',
        value: 'DELIVERY_CONSMENU_CONF_MESSAGE',
      },
      {
        defaultText: 'Please pickup your order from the following locations.You will recieve a text message when your order is ready for pickup',
        key: 'customMessage.PICKUP_CONSMENU_CONF_MESSAGE',
        type: 'customMessage',
        value: 'PICKUP_CONSMENU_CONF_MESSAGE',
      },
    ]
  },
  ReceiptPage: {
    screenLabel: 'Receipt Page',
    image: [receiptPage],
    customLabels: [
      // {
      //   defaultText: 'Subtotal',
      //   key: 'label.SUBTOTAL',
      //   image: [receiptFirstPage],
      //   screenName: ['receiptFirstPage']
      // },
      // {
      //   defaultText: 'Tax',
      //   key: 'label.TAX',
      //   image: [receiptFirstPage],
      //   screenName: ['receiptFirstPage']
      // },
      // {
      //   defaultText: 'Tip',
      //   key: 'label.TIP',
      //   image: [receiptFirstPage],
      //   screenName: ['receiptFirstPage']
      // },
      // {
      //   defaultText: 'Total',
      //   key: 'label.TOTAL',
      //   image: [receiptFirstPage],
      //   screenName: ['receiptFirstPage']
      // },
      {
        defaultText: 'Choose a receipt option',
        key: 'label.RECEIPT_OPTION',
        type: 'label',
        value: 'RECEIPT_OPTION',
        image: [receiptFirstPage],
        screenName: ['receiptFirstPage']
      },
      {
        defaultText: 'No Receipt',
        key: 'label.NO_RECEIPT',
        type: 'label',
        value: 'NO_RECEIPT',
        image: [receiptFirstPage],
        screenName: ['receiptFirstPage']
      },
      /* {
        defaultText: 'No Receipt',
        key: 'label.NO_RECEIPT',
        type: 'label',
        value: 'NO_RECEIPT',
        image: [receiptFirstPage],
        screenName: ['receiptFirstPage']
      }, */
      {
        defaultText: 'SMS',
        key: 'label.SMS',
        type: 'label',
        value: 'SMS',
        image: [receiptFirstPage],
        screenName: ['receiptFirstPage']
      },
      {
        defaultText: 'Email',
        key: 'label.EMAIL',
        type: 'label',
        value: 'EMAIL',
        image: [receiptFirstPage],
        screenName: ['receiptFirstPage']
      },
      // {
      //   defaultText: 'Please Enter Your Mobile Number',
      //   key: 'label.MOB_NUMBER',
      //   image: [receiptSecondPage],
      //   screenName: ['receiptSecondPage']
      // },
      {
        defaultText: 'Close',
        key: 'label.CLOSE',
        type: 'label',
        value: 'CLOSE',
        image: [receiptSecondPage],
        screenName: ['receiptSecondPage']
      },
      {
        defaultText: 'Submit',
        key: 'label.SUBMIT',
        type: 'label',
        value: 'SUBMIT',
        image: [receiptSecondPage],
        screenName: ['receiptSecondPage']
      },
      {
        defaultText: 'Pay Now Success Message',
        key: 'label.PAY_NOW_SUCCESS_MSG ',
        type: 'label',
        value: 'PAY_NOW_SUCCESS_MSG',
        image: [payNowSuccessMsg],
        screenName: ['payNowSuccessMsg']
      },
      {
        defaultText: 'Curb Side/Walkup Pay Later',
        key: 'label.CURB_SIDE_WALKUP_PAY_LATER',
        type: 'label',
        value: 'CURB_SIDE_WALKUP_PAY_LATER',
        image: [curdSideWalkupPayLater],
        screenName: ['curdSideWalkupPayLater']
      },
      {
        defaultText: 'Online Pay Later',
        key: 'label.ONLINE_PAY_LATER',
        type: 'label',
        value: 'ONLINE_PAY_LATER',
        image: [oneLinePayLater],
        screenName: ['oneLinePayLater']
      },
      {
        defaultText: 'Please Enter Your Mobile Number',
        key: 'customMessage.ENTER_MOBILE_NUMBER',
        type: 'customMessage',
        value: 'ENTER_MOBILE_NUMBER',
        image: [oneLinePayLater],
        screenName: ['oneLinePayLater']
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'label.MOBILE_NUMBER',
        type: 'label',
        value: 'MOBILE_NUMBER',
        image: [oneLinePayLater],
        screenName: ['oneLinePayLater']
      },
      {
        defaultText: 'Please Enter Your Email Id',
        key: 'customMessage.ENTER_EMAIL_ID',
        type: 'customMessage',
        value: 'ENTER_EMAIL_ID',
        image: [oneLinePayLater],
        screenName: ['oneLinePayLater']
      },
      {
        defaultText: 'Enter Email Address',
        key: 'label.EMAIL_ADDRESS',
        type: 'label',
        value: 'EMAIL_ADDRESS',
        image: [oneLinePayLater],
        screenName: ['oneLinePayLater']
      },
      {
        defaultText: 'View Receipt',
        key: 'label.VIEW_RECEIPT',
        type: 'label',
        value: 'VIEW_RECEIPT',
      },
      {
        defaultText: 'TBL',
        key: 'label.RECEIPT_TABLE_NUMBER',
        type: 'label',
        value: 'RECEIPT_TABLE_NUMBER',
      },
      {
        defaultText: 'deliver to',
        key: 'label.RECEIPT_DELIVER_TO',
        type: 'label',
        value: 'RECEIPT_DELIVER_TO',
      },
      {
        defaultText: 'Transaction Id',
        key: 'label.RECEIPT_TXN_ID',
        type: 'label',
        value: 'RECEIPT_TXN_ID',
      },
      {
        defaultText: 'PayPal Email',
        key: 'label.RECEIPT_PAYPAL_EMAIL',
        type: 'label',
        value: 'RECEIPT_PAYPAL_EMAIL',
      },
      {
        defaultText: 'Paid to',
        key: 'customMessage.RECEIPT_PAID_TO',
        type: 'customMessage',
        value: 'RECEIPT_PAID_TO',
      },
      {
        defaultText: 'Paid to Gift Card ending with',
        key: 'customMessage.RECEIPT_PAID_GIFTCARD',
        type: 'customMessage',
        value: 'RECEIPT_PAID_GIFTCARD',
      },
      {
        defaultText: 'Paid with PayPal',
        key: 'customMessage.RECEIPT_PAID_WITH_PAYPAL',
        type: 'customMessage',
        value: 'RECEIPT_PAID_WITH_PAYPAL',
      },
      {
        defaultText: 'Receipt has been sent. Resend?',
        key: 'customMessage.RECEIPT_RESEND_OPTION',
        type: 'customMessage',
        value: 'RECEIPT_RESEND_OPTION',
      },
    ]
  },
  HomePageAndRefill: {
    screenLabel: 'Home Page and Refill',
    image: [HomePage],
    customLabels: [
      {
        defaultText: 'Click To Pay Label',
        key: 'label.CLICKTOPAYLABEL',
        type: 'label',
        value: 'CLICKTOPAYLABEL',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'Add items to refill',
        key: 'label.ADD_ITEMS_TO_REFILL',
        type: 'label',
        value: 'ADD_ITEMS_TO_REFILL',
        image: [addItemsToRefill],
        screenName: ['addItemsToRefill']
      },
      // {
      //   defaultText: 'Add to Bag',
      //   key: 'label.ADD_TO_BAG',
      //   image: [addItemsToRefill],
      //   screenName: ['addItemsToRefill']
      // },
      {
        defaultText: 'Remove',
        key: 'label.REFILL_REMOVE',
        type: 'label',
        value: 'REFILL_REMOVE',
        image: [addItemsToRefill2],
        screenName: ['addItemsToRefill2']
      },
      {
        defaultText: 'Refill',
        key: 'label.REFILL',
        type: 'label',
        value: 'REFILL',
        image: [refillCartMsg],
        screenName: ['refillCartMsg']
      },
      {
        defaultText: 'Refill Empty Cart Message',
        key: 'label.REFILL_EMPTY_CART_MSG',
        type: 'label',
        value: 'REFILL_EMPTY_CART_MSG',
        image: [refillEmptyCartMSg],
        screenName: ['refillEmptyCartMSg']
      },
      {
        defaultText: 'Enter check number below',
        key: 'customMessage.ENTER_CHECK_NUMBER',
        type: 'customMessage',
        value: 'ENTER_CHECK_NUMBER',
      },
      {
        defaultText: 'Skip check in',
        key: 'label.SKIP_CHECK_IN',
        type: 'label',
        value: 'SKIP_CHECK_IN',
      },
      {
        defaultText: 'Check In',
        key: 'label.CHECK_NUMBER_NEXT',
        type: 'label',
        value: 'CHECK_NUMBER_NEXT',
      },
    ]
  },
  footer: {
    screenLabel: 'Footer',
    image: [FooterMenus],
    customLabels: [
      {
        defaultText: 'Home',
        key: 'label.FOOTER_HOME',
        type: 'label',
        value: 'FOOTER_HOME',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'Menus',
        key: 'label.FOOTER_MENUS',
        type: 'label',
        value: 'FOOTER_MENUS',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'Order',
        key: 'label.FOOTER_ORDERS',
        type: 'label',
        value: 'FOOTER_ORDERS',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'My Bag',
        key: 'label.FOOTER_MY_BAG',
        type: 'label',
        value: 'FOOTER_MY_BAG',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'Favorite',
        key: 'label.FAVOURITE',
        type: 'label',
        value: 'FAVOURITE',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'Restaurants',
        key: 'label.FOOTER_STORE',
        type: 'label',
        value: 'FOOTER_STORE',
        image: [ClickToPayLabel],
        screenName: ['ClickToPayLabel']
      },
      {
        defaultText: 'My Check',
        key: 'label.FOOTER_MY_CHECK',
        type: 'label',
        value: 'FOOTER_MY_CHECK',
      },
      {
        defaultText: 'Call Server',
        key: 'label.FOOTER_CALL_SERVER',
        type: 'label',
        value: 'FOOTER_CALL_SERVER',
      },
      {
        defaultText: 'Privacy Policy',
        key: 'label.PRIVACY_POLICY',
        type: 'label',
        value: 'PRIVACY_POLICY',
      },
      {
        defaultText: 'Terms and Conditions',
        key: 'label.TERMS_AND_CONDITIONS',
        type: 'label',
        value: 'TERMS_AND_CONDITIONS',
      },
      {
        defaultText: 'Cookies',
        key: 'label.COOKIES',
        type: 'label',
        value: 'COOKIES',
      },
      {
        defaultText: 'Browse',
        key: 'label.BROWSE',
        type: 'label',
        value: 'BROWSE',
      },
    ]
  },
  waitList: {
    screenLabel: 'Wait List',
    image: [waitList],
    customLabels: [
      {
        defaultText: 'What would you like to today?',
        key: 'label.WHAT_WOULD_YOU_LIKE',
        type: 'label',
        value: 'WHAT_WOULD_YOU_LIKE',
        image: [waitListFirstScreen],
        screenName: ['waitListFirstScreen']
      },
      {
        defaultText: 'Dine In',
        key: 'label.DINE_IN',
        type: 'label',
        value: 'DINE_IN',
        image: [waitListFirstScreen],
        screenName: ['waitListFirstScreen']
      },
      {
        defaultText: 'Take Out',
        key: 'label.TAKE_OUT',
        type: 'label',
        value: 'TAKE_OUT',
        image: [waitListFirstScreen],
        screenName: ['waitListFirstScreen']
      },
      {
        defaultText: 'Number of Guest In Your Party',
        key: 'label.NO_OF_GUEST',
        type: 'label',
        value: 'NO_OF_GUEST',
        image: [waitListSecondScreen],
        screenName: ['waitListSecondScreen']
      },
      {
        defaultText: 'Of your guest,How Many are Children?',
        key: 'label.HOW_MANY_CHILDREN',
        type: 'label',
        value: 'HOW_MANY_CHILDREN',
        image: [waitListSecondScreen],
        screenName: ['waitListSecondScreen']
      },
      {
        defaultText: 'Check Availability',
        key: 'label.CHECK_AVAILABILITY',
        type: 'label',
        value: 'CHECK_AVAILABILITY',
        image: [waitListSecondScreen],
        screenName: ['waitListSecondScreen']
      },
      {
        defaultText: 'Add to Wait List',
        key: 'label.ADD_TO_WAIT_LIST',
        type: 'label',
        value: 'ADD_TO_WAIT_LIST',
        image: [waitListThirdScreen],
        screenName: ['waitListThirdScreen']
      },
      {
        defaultText: 'Add to wait List PoP Up Message',
        key: 'label.ADD_TO_WAIT_LIST_POP_MSG',
        type: 'label',
        value: 'ADD_TO_WAIT_LIST_POP_MSG',
        image: [waitListPoPUPScreen],
        screenName: ['waitListPoPUPScreen']
      },
      {
        defaultText: 'Add to wait List Success Message',
        key: 'label.ADD_TO_WAIT_LIST_SUCCESS_MSG',
        type: 'label',
        value: 'ADD_TO_WAIT_LIST_SUCCESS_MSG',
        image: [waitListSuccessScreen],
        screenName: ['waitListSuccessScreen']
      },
      {
        defaultText: 'Name',
        key: 'label.WAIT_LIST_NAME',
        type: 'label',
        value: 'WAIT_LIST_NAME',
      },
      {
        defaultText: 'Party Size',
        key: 'label.WAIT_LIST_PARTY_SIZE',
        type: 'label',
        value: 'WAIT_LIST_PARTY_SIZE',
      },
      {
        defaultText: 'Country Code',
        key: 'label.WAIT_LIST_COUNTRY_CODE',
        type: 'label',
        value: 'WAIT_LIST_COUNTRY_CODE',
      },
      {
        defaultText: 'Email Address (Optional)',
        key: 'label.WAIT_LIST_EMAIL_ADDRESS',
        type: 'label',
        value: 'WAIT_LIST_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Note (Optional)',
        key: 'label.WAIT_LIST_NOTE',
        type: 'label',
        value: 'WAIT_LIST_NOTE',
      },
      {
        defaultText: 'Submit',
        key: 'label.WAIT_LIST_SUBMIT',
        type: 'label',
        value: 'WAIT_LIST_SUBMIT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.WAIT_LIST_CANCEL',
        type: 'label',
        value: 'WAIT_LIST_CANCEL',
      },
      {
        defaultText: 'Modify',
        key: 'label.WAIT_LIST_MODIFY',
        type: 'label',
        value: 'WAIT_LIST_MODIFY',
      },
      {
        defaultText: 'Yes',
        key: 'label.WAIT_LIST_YES',
        type: 'label',
        value: 'WAIT_LIST_YES',
      },
      {
        defaultText: 'No',
        key: 'label.WAIT_LIST_NO',
        type: 'label',
        value: 'WAIT_LIST_NO',
      },
      {
        defaultText: 'Phone',
        key: 'label.WAIT_LIST_WAIT_PHONE_NUMBER',
        type: 'label',
        value: 'WAIT_LIST_WAIT_PHONE_NUMBER',
      },
      {
        defaultText: 'Update',
        key: 'label.WAIT_LIST_UPDATE',
        type: 'label',
        value: 'WAIT_LIST_UPDATE',
      },
      {
        defaultText: 'Email',
        key: 'label.WAIT_LIST_EMAIL_INFO',
        type: 'label',
        value: 'WAIT_LIST_EMAIL_INFO',
      },
      {
        defaultText: 'Note',
        key: 'label.WAIT_LIST_NOTE_INFO',
        type: 'label',
        value: 'WAIT_LIST_NOTE_INFO',
      },
      {
        defaultText: 'Enter mobile number',
        key: 'customMessage.WAIT_LIST_ENTER_MOBILE_NUMBER',
        type: 'customMessage',
        value: 'WAIT_LIST_ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Parties ahead of you',
        key: 'customMessage.WAIT_LIST_PARTIES_OF_AHEAD',
        type: 'customMessage',
        value: 'WAIT_LIST_PARTIES_OF_AHEAD',
      },
      {
        defaultText: 'Wait List Confirmation',
        key: 'customMessage.WAIT_LIST_INFO',
        type: 'customMessage',
        value: 'WAIT_LIST_INFO',
      },
      {
        defaultText: 'Are you sure you want to cancel your wait list reservation',
        key: 'customMessage.WAIT_LIST_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'WAIT_LIST_DELETE_MESSAGE',
      },
      {
        defaultText: 'You have been added to the wait list',
        key: 'customMessage.WAIT_LIST_ADDED_MESSAGE',
        type: 'customMessage',
        value: 'WAIT_LIST_ADDED_MESSAGE',
      },
      {
        defaultText: 'You have been removed from the wait list',
        key: 'customMessage.WAIT_LIST_CANCEL_MESSAGE',
        type: 'customMessage',
        value: 'WAIT_LIST_CANCEL_MESSAGE',
      },
      {
        defaultText: 'Your wait list has been modified successfully',
        key: 'customMessage.WAIT_LIST_UPDATE_MESSAGE',
        type: 'customMessage',
        value: 'WAIT_LIST_UPDATE_MESSAGE',
      },
      {
        defaultText: 'Name is required',
        key: 'customMessage.WAIT_LIST_NAME_IS_REQUIRED',
        type: 'customMessage',
        value: 'WAIT_LIST_NAME_IS_REQUIRED',
      },
      {
        defaultText: 'Please select party size',
        key: 'customMessage.WAIT_LIST_PLEASE_SELECT_PARTY_SIZE',
        type: 'customMessage',
        value: 'WAIT_LIST_PLEASE_SELECT_PARTY_SIZE',
      },
      {
        defaultText: 'Invalid email',
        key: 'customMessage.WAIT_LIST_INVALID_EMAIL',
        type: 'customMessage',
        value: 'WAIT_LIST_INVALID_EMAIL',
      },
      {
        defaultText: 'Wait list has been cancelled - please contact the location directly If you have any questions',
        key: 'customMessage.WAIT_LIST_CANCEL_FROM_SMS',
        type: 'customMessage',
        value: 'WAIT_LIST_CANCEL_FROM_SMS',
      },
    ]
  },
  splitCheck: {
    screenLabel: 'Split Check',
    image: [splitCheck],
    customLabels: [
      {
        defaultText: 'FULL CHECK',
        key: 'label.FULL_CHECK',
        type: 'label',
        value: 'FULL_CHECK',
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        defaultText: 'SPLIT EVENLY',
        key: 'label.SPLIT_EVENLY',
        type: 'label',
        value: 'SPLIT_EVENLY',
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        defaultText: 'Select Items',
        key: 'label.SPLIT_ITEMS',
        type: 'label',
        value: 'SPLIT_ITEMS',
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        defaultText: 'Pay An Amount',
        key: 'label.PAY_AN_AMOUNT',
        type: 'label',
        value: 'PAY_AN_AMOUNT',
      },
      {
        defaultText: 'Pay My Items',
        key: 'label.PAY_MY_ITEMS',
        type: 'label',
        value: 'PAY_MY_ITEMS',
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        defaultText: 'Pay By Guest',
        key: 'label.PAY_BY_GUEST',
        type: 'label',
        value: 'PAY_BY_GUEST',
        image: [splitCheckFirstScreen],
        screenName: ['splitCheckFirstScreen']
      },
      {
        defaultText: 'Please Select How Many People Will Split this',
        key: 'label.HOW_MANY_PPL',
        type: 'label',
        value: 'HOW_MANY_PPL',
        image: [splitCheckSecondScreen],
        screenName: ['splitCheckSecondScreen']
      },
      /* {
        defaultText: 'Total Due',
        key: 'label.TOTAL_DUE',
        type: 'label',
        value: 'TOTAL_DUE',
        image: [splitCheckSecondScreen],
        screenName: ['splitCheckSecondScreen']
      }, */
      {
        defaultText: 'Continue',
        key: 'label.CONTINUE',
        type: 'label',
        value: 'CONTINUE',
        image: [splitCheckSecondScreen],
        screenName: ['splitCheckSecondScreen']
      },
      {
        defaultText: 'Pay',
        key: 'label.PAY',
        type: 'label',
        value: 'PAY',
        image: [splitCheckThirdScreen],
        screenName: ['splitCheckThirdScreen']
      },
      {
        defaultText: 'Pay Remaining Balance',
        key: 'label.PAY_REMAINING_BALANCE',
        type: 'label',
        value: 'PAY_REMAINING_BALANCE',
        image: [splitCheckThirdScreen],
        screenName: ['splitCheckThirdScreen']
      },
      {
        defaultText: 'Pay Selected',
        key: 'label.PAY_SELECTED',
        type: 'label',
        value: 'PAY_SELECTED',
      },
      {
        defaultText: 'Pay all',
        key: 'label.PAY_ALL',
        type: 'label',
        value: 'PAY_ALL',
      },
      {
        defaultText: 'Select seats for payment',
        key: 'customMessage.PAY_SELECTED_SEATS',
        type: 'customMessage',
        value: 'PAY_SELECTED_SEATS',
      },
      {
        defaultText: 'Select items for payment',
        key: 'customMessage.PAY_SELECTED_ITEMS',
        type: 'customMessage',
        value: 'PAY_SELECTED_ITEMS',
      },
      {
        defaultText: 'Please select a check to pay',
        key: 'customMessage.SPLITED_CHECK_LIST_TITLE',
        type: 'customMessage',
        value: 'SPLITED_CHECK_LIST_TITLE',
      },
    ]
  },
  daisyChain: {
    screenLabel: 'Daisy Chain',
    image: [daisyChain],
    customLabels: [
      // {
      //   defaultText: 'Daisy Chain',
      //   key: 'label.DAISYCHAIN',
      //   image: [daisyChainFirstScreen],
      //   screenName: ['daisyChainFirstScreen']
      // },
      // {
      //   defaultText: 'Yes',
      //   key: 'label.DC_ORDER_PLACE_YES',
      //   image: [daisyChainFirstScreen],
      //   screenName: ['daisyChainFirstScreen']
      // },
      // {
      //   defaultText: 'No',
      //   key: 'label.DC_ORDER_PLACE_NO',
      //   image: [daisyChainFirstScreen],
      //   screenName: ['daisyChainFirstScreen']
      // },
      {
        defaultText: 'Are you the last person to add to this order?',
        key: 'label.DC_ORDER_MSG',
        type: 'label',
        value: 'DC_ORDER_MSG',
        image: [daisyChainSecondScreen],
        screenName: ['daisyChainSecondScreen']
      },
      {
        defaultText: 'Enter the name of this order',
        key: 'label.DC_NAME_OF_THIS_ORDER',
        type: 'label',
        value: 'DC_NAME_OF_THIS_ORDER',
        image: [daisyChainFirstScreen],
        screenName: ['daisyChainFirstScreen']
      },
      {
        defaultText: 'Are you the only person sending an order right now?',
        key: 'label.DC_ORDER_PLACE_MSG',
        type: 'label',
        value: 'DC_ORDER_PLACE_MSG',
        image: [daisyChainFirstScreen],
        screenName: ['daisyChainFirstScreen']
      },
      {
        defaultText: 'Yes, I’m the only one',
        key: 'label.DAISY_FIRST_PERSON_YES',
        type: 'label',
        value: 'DAISY_FIRST_PERSON_YES',
      },
      {
        defaultText: 'No, I’m not the only one',
        key: 'label.DAISY_FIRST_PERSON_NO',
        type: 'label',
        value: 'DAISY_FIRST_PERSON_NO',
      },
      {
        defaultText: 'Yes, I’m the last one',
        key: 'label.DAISY_LAST_PERSON_YES',
        type: 'label',
        value: 'DAISY_LAST_PERSON_YES',
      },
      {
        defaultText: 'No, I’m not the last one',
        key: 'label.DAISY_LAST_PERSON_NO',
        type: 'label',
        value: 'DAISY_LAST_PERSON_NO',
      },
    ]
  },
  badges: {
    screenLabel: 'Badge',
    image: [],
    customLabels: [
      // {
      //   defaultText: 'Daisy Chain',
      //   key: 'label.DAISYCHAIN',
      //   image: [daisyChainFirstScreen],
      //   screenName: ['daisyChainFirstScreen']
      // },
      {
        defaultText: 'View Only',
        key: 'label.VIEW_ONLY',
        type: 'label',
        value: 'VIEW_ONLY',
        image: [],
        screenName: []
      },
      {
        defaultText: 'New Item',
        key: 'label.NEW_ITEM',
        type: 'label',
        value: 'NEW_ITEM',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Limited Time',
        key: 'label.LIMITED_TIME',
        type: 'label',
        value: 'LIMITED_TIME',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Featured',
        key: 'label.FEATURED',
        type: 'label',
        value: 'FEATURED',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Special',
        key: 'label.SPECIAL',
        type: 'label',
        value: 'SPECIAL',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Limited Quantity',
        key: 'label.LIMITED_QUANTITY',
        type: 'label',
        value: 'LIMITED_QUANTITY',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Additional Time',
        key: 'label.ADDITIONAL_TIME',
        type: 'label',
        value: 'ADDITIONAL_TIME',
        image: [],
        screenName: []
      },
      {
        defaultText: 'Time Restricted',
        key: 'label.TIME_RESTRICTED',
        type: 'label',
        value: 'TIME_RESTRICTED',
        image: [],
        screenName: []
      }
    ]
  },
  loyalty: {
    screenLabel: 'Loyalty',
    image: [loyalty],
    customLabels: [
      {
        defaultText: 'Would you like to join rewards program?',
        key: 'customMessage.LOYALTY_REWARDS_MESSAGE',
        type: 'customMessage',
        value: 'LOYALTY_REWARDS_MESSAGE',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Already a member',
        key: 'label.LOYALTY_ALREADY_MEMBER',
        type: 'label',
        value: 'LOYALTY_ALREADY_MEMBER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Join now',
        key: 'label.LOYALTY_JOIN_NOW',
        type: 'label',
        value: 'LOYALTY_JOIN_NOW',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'How would you like to look up your account?',
        key: 'customMessage.LOYALTY_LOOKUP_ACCOUNT',
        type: 'customMessage',
        value: 'LOYALTY_LOOKUP_ACCOUNT',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Phone Number',
        key: 'label.LOYALTY_PHONE_NUMBER',
        type: 'label',
        value: 'LOYALTY_PHONE_NUMBER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Email Address',
        key: 'label.LOYALTY_EMAIL_ADDRESS',
        type: 'label',
        value: 'LOYALTY_EMAIL_ADDRESS',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Account Number',
        key: 'label.LOYALTY_ACC_NUMBER',
        type: 'label',
        value: 'LOYALTY_ACC_NUMBER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Please enter your phone number',
        key: 'customMessage.LOYALTY_PHONE_NUMBER_LABEL',
        type: 'customMessage',
        value: 'LOYALTY_PHONE_NUMBER_LABEL',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Please enter your email address',
        key: 'customMessage.LOYALTY_EMAIL_ADDRESS_LABEL',
        type: 'customMessage',
        value: 'LOYALTY_EMAIL_ADDRESS_LABEL',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Please enter your account number',
        key: 'customMessage.LOYALTY_ACC_NUMBER_LABEL',
        type: 'customMessage',
        value: 'LOYALTY_ACC_NUMBER_LABEL',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Account Number',
        key: 'label.LOYALTY_ACCOUNT_NUMBER',
        type: 'label',
        value: 'LOYALTY_ACCOUNT_NUMBER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Offers available',
        key: 'label.LOYALTY_SELECT_AN_OFFER',
        type: 'label',
        value: 'LOYALTY_SELECT_AN_OFFER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Skip Offer',
        key: 'label.LOYALTY_SKIP_OFFER',
        type: 'label',
        value: 'LOYALTY_SKIP_OFFER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Apply Offer',
        key: 'label.LOYALTY_APPLY_OFFER',
        type: 'label',
        value: 'LOYALTY_APPLY_OFFER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Account',
        key: 'label.LOYALTY_DISPLAY_ACC_NUMBER',
        type: 'label',
        value: 'LOYALTY_DISPLAY_ACC_NUMBER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Offer',
        key: 'label.LOYALTY_DISPLAY_OFFER',
        type: 'label',
        value: 'LOYALTY_DISPLAY_OFFER',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Amount',
        key: 'label.LOYALTY_OFFER_AMOUNT',
        type: 'label',
        value: 'LOYALTY_OFFER_AMOUNT',
        image: [loyalty],
        screenName: ['loyalty']
      },
      {
        defaultText: 'Valid from:',
        key: 'label.LOYALTY_VALID_FROM',
        type: 'label',
        value: 'LOYALTY_VALID_FROM',
      },
      {
        defaultText: 'Expires on:',
        key: 'label.LOYALTY_EXPIRES_ON',
        type: 'label',
        value: 'LOYALTY_EXPIRES_ON',
      },
      {
        defaultText: 'No offers currently available',
        key: 'label.NO_OFFERS_AVALIABLE',
        type: 'label',
        value: 'NO_OFFERS_AVALIABLE',
      },
      {
        defaultText: 'Enter First Name',
        key: 'customMessage.ENTER_LOYALTY_FIRSTNAME',
        type: 'customMessage',
        value: 'ENTER_LOYALTY_FIRSTNAME',
      },
      {
        defaultText: 'Enter Last Name',
        key: 'customMessage.ENTER_LOYALTY_LASTNAME',
        type: 'customMessage',
        value: 'ENTER_LOYALTY_LASTNAME',
      },
      {
        defaultText: 'Forgot password',
        key: 'customMessage.LOOKUP_FORGOT_PASSWORD',
        type: 'customMessage',
        value: 'LOOKUP_FORGOT_PASSWORD',
      },
      {
        defaultText: 'Loyalty Sign In',
        key: 'label.LOYALTY_SIGN_IN',
        type: 'label',
        value: 'LOYALTY_SIGN_IN',
      },
      {
        defaultText: 'Loyalty Sign Up',
        key: 'label.LOYALTY_SIGN_UP',
        type: 'label',
        value: 'LOYALTY_SIGN_UP',
      },
      {
        defaultText: 'Loyalty Forgot Password',
        key: 'label.LOYALTY_FORGOT_PASSWORD',
        type: 'label',
        value: 'LOYALTY_FORGOT_PASSWORD',
      },
      {
        defaultText: 'Tier',
        key: 'label.TIER_LABEL_TEXT',
        type: 'label',
        value: 'TIER_LABEL_TEXT',
      },
      {
        defaultText: 'Points',
        key: 'label.POINTS_LABEL_TEXT',
        type: 'label',
        value: 'POINTS_LABEL_TEXT',
      },
      {
        defaultText: 'Estimated points earned',
        key: 'label.ESTIMATED_POINTS_EARNED',
        type: 'label',
        value: 'ESTIMATED_POINTS_EARNED',
      },
      {
        defaultText: 'Enter coupon number',
        key: 'customMessage.ENTER_COUPON_NUMBER',
        type: 'customMessage',
        value: 'ENTER_COUPON_NUMBER',
      },
    ]
  },
  preAuthPage: {
    screenLabel: 'PreAuth Changes',
    image: [preAuth],
    customLabels: [
      {
        defaultText: 'Keep It Open',
        key: 'label.KEEP_IT_OPEN',
        type: 'label',
        value: 'KEEP_IT_OPEN',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'I Agree',
        key: 'label.I_AGREE',
        type: 'label',
        value: 'I_AGREE',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Authorize Card',
        key: 'label.AUTHORIZE_CARD',
        type: 'label',
        value: 'AUTHORIZE_CARD',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Hold',
        key: 'label.HOLD',
        type: 'label',
        value: 'HOLD',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'If you leave without settling your tab, your hold will be used to settle the final bill amount.',
        key: 'customMessage.PRE_AUTH_PERCENTAGE_ZERO',
        type: 'customMessage',
        value: 'PRE_AUTH_PERCENTAGE_ZERO',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'If you leave without settling your tab, your card will be used to settle the final bill amount plus a 20% gratuity',
        key: 'customMessage.PRE_AUTH_PERCENTAGE_NOT_ZERO',
        type: 'customMessage',
        value: 'PRE_AUTH_PERCENTAGE_NOT_ZERO',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'A hold will be placed on your card. I agree to settle my tab for the final check amount before departing this establishment',
        key: 'customMessage.PRE_AUTH_HOLD',
        type: 'customMessage',
        value: 'PRE_AUTH_HOLD',
        image: [curbside],
        screenName: ['curbside']
      },
      // {
      //   defaultText: 'If I leave without settling my tab, I agree to allow this location to to settle my final bill amount.',
      //   key: 'customMessage.PRE_AUTH_LOCATION_PERCENTAGE_ZERO',
      //   type: 'customMessage',
      //   value: 'PRE_AUTH_LOCATION_PERCENTAGE_ZERO',
      //   image: [curbside],
      //   screenName: ['curbside']
      // },
      // {
      //   defaultText: 'If I leave without settling my tab, I agree to allow this location to to settle my final bill amount plus a 20% gratuity.',
      //   key: 'customMessage.PRE_AUTH_LOCATION_PERCENTAGE_NOT_ZERO',
      //   type: 'customMessage',
      //   value: 'PRE_AUTH_LOCATION_PERCENTAGE_NOT_ZERO',
      //   image: [curbside],
      //   screenName: ['curbside']
      // },
      {
        defaultText: 'This location requires a credit card hold before placing an order. You must settle your tab for the final check amount before departing this establishment.',
        key: 'customMessage.PRE_AUTH_LOCATION_CREDIT_CARD',
        type: 'customMessage',
        value: 'PRE_AUTH_LOCATION_CREDIT_CARD',
        image: [curbside],
        screenName: ['curbside']
      },
    ]
  },
  tableGroupOrdering: {
    screenLabel: 'Table Group Ordering',
    image: [tableGroupOrdering],
    customLabels: [
      {
        defaultText: 'Please Present this QR code to any other member of your party who would like to join this check',
        key: 'customMessage.PLEASE_PRESENT_THIS_QR',
        type: 'customMessage',
        value: 'PLEASE_PRESENT_THIS_QR',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Want to invite over SMS?Copy the link below and send it using your SMS app',
        key: 'customMessage.WANT_TO_INVITE_OVER_SMS',
        type: 'customMessage',
        value: 'WANT_TO_INVITE_OVER_SMS',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Please request a QR from your party host to join this table.The host can generate...',
        key: 'customMessage.PLEASE_REQUEST_QR_PARTY_HOST',
        type: 'customMessage',
        value: 'PLEASE_REQUEST_QR_PARTY_HOST',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'There is a check open on this table.would you like to join this check?',
        key: 'customMessage.CHECK_TABLE_OPEN',
        type: 'customMessage',
        value: 'CHECK_TABLE_OPEN',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Join Check and continue',
        key: 'label.JOIN_CHECK_AND_CONTINUE',
        type: 'label',
        value: 'JOIN_CHECK_AND_CONTINUE',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Start a new check',
        key: 'label.START_A_NEW_CHECK',
        type: 'label',
        value: 'START_A_NEW_CHECK',
        image: [curbside],
        screenName: ['curbside']
      },
      {
        defaultText: 'Join existing check',
        key: 'label.JOIN_EXISTING_CHECK',
        type: 'label',
        value: 'JOIN_EXISTING_CHECK',
      },
      {
        defaultText: 'Join a party with',
        key: 'label.JOIN_PARTY_WITH',
        type: 'label',
        value: 'JOIN_PARTY_WITH',
      },
      {
        defaultText: 'To enhance your ordering experience, please select from one of the following options',
        key: 'customMessage.CHECK_JOIN_ORDERING_EXP_MSG',
        type: 'customMessage',
        value: 'CHECK_JOIN_ORDERING_EXP_MSG',
      },
      {
        defaultText: 'Paying for myself',
        key: 'label.PAYING_FOR_MYSELF',
        type: 'label',
        value: 'PAYING_FOR_MYSELF',
      },
      {
        defaultText: 'Paying for myself and others',
        key: 'label.PAYING_FOR_MYSELF_AND_OTHERS',
        type: 'label',
        value: 'PAYING_FOR_MYSELF_AND_OTHERS',
      },
      {
        defaultText: 'Someone else is paying for me',
        key: 'label.SOMEONE_ELSE_IS_PAYING',
        type: 'label',
        value: 'SOMEONE_ELSE_IS_PAYING',
      },
      {
        defaultText: 'Please confirm your table',
        key: 'label.CHANGE_LOCATION_UNCHECKED',
        type: 'label',
        value: 'CHANGE_LOCATION_UNCHECKED',
      },
      {
        defaultText: 'Please scan the QR code on your table to confirm your location',
        key: 'customMessage.PLEASE_CONFIRM_TABLE',
        type: 'customMessage',
        value: 'PLEASE_CONFIRM_TABLE',
      },
      {
        defaultText: 'Scan host\'s Invite QR',
        key: 'label.SCAN_HOST_QR_CODE',
        type: 'label',
        value: 'SCAN_HOST_QR_CODE',
      },
      {
        defaultText: 'Please select a check to join',
        key: 'customMessage.PLEASE_SELECT_CHECK',
        type: 'customMessage',
        value: 'PLEASE_SELECT_CHECK',
      },
      {
        defaultText: 'You are joining the following event',
        key: 'customMessage.GUEST_EVENT_JOIN_CHECK_MESSAGE',
        type: 'customMessage',
        value: 'GUEST_EVENT_JOIN_CHECK_MESSAGE',
      },
      {
        defaultText: 'Click next to continue',
        key: 'label.CLICK_TO_CONTINUE',
        type: 'label',
        value: 'CLICK_TO_CONTINUE',
      },
    ]
  },
  immediateFeature: {
    screenLabel: 'Immediate Feature',
    image: [immediateFeature],
    customLabels: [
      {
        defaultText: 'Would you like it send right away?',
        key: 'customMessage.IMMEDIATE_ORDER_SEND',
        type: 'customMessage',
        value: 'IMMEDIATE_ORDER_SEND',
      },
      {
        defaultText: 'Send Now',
        key: 'label.SEND_NOW',
        type: 'label',
        value: 'SEND_NOW',
      },
      {
        defaultText: 'Add to Bag',
        key: 'label.IMMEDIATE_ADD_TO_BAG',
        type: 'label',
        value: 'IMMEDIATE_ADD_TO_BAG',
      },
      {
        defaultText: 'There was an error sending your order. Would you like to add to cart instead?',
        key: 'customMessage.IMMEDIATE_ORDER_FAILURE',
        type: 'customMessage',
        value: 'IMMEDIATE_ORDER_FAILURE',
      },
    ]
  },
  survey: {
    screenLabel: 'Survey',
    customLabels: [
      {
        defaultText: 'Skip Survey',
        key: 'label.SKIP_SURVEY',
        type: 'label',
        value: 'SKIP_SURVEY',
      },
      {
        defaultText: 'Previous',
        key: 'label.SUR_PREVIOUS',
        type: 'label',
        value: 'SUR_PREVIOUS',
      },
      {
        defaultText: 'Feedback',
        key: 'label.SURVEY_FEEDBACK',
        type: 'label',
        value: 'SURVEY_FEEDBACK',
      },
      {
        defaultText: 'Next',
        key: 'label.SURVEY_NEXT',
        type: 'label',
        value: 'SURVEY_NEXT',
      },
      {
        defaultText: 'Done',
        key: 'label.SURVEY_DONE',
        type: 'label',
        value: 'SURVEY_DONE',
      },
      {
        defaultText: 'Continue',
        key: 'label.SURVEY_CONTINUE',
        type: 'label',
        value: 'SURVEY_CONTINUE',
      },
      {
        defaultText: 'Yes',
        key: 'label.SURVEY_CONTACT_FORM_YES',
        type: 'label',
        value: 'SURVEY_CONTACT_FORM_YES',
      },
      {
        defaultText: 'No',
        key: 'label.SURVEY_CONTACT_FORM_NO',
        type: 'label',
        value: 'SURVEY_CONTACT_FORM_NO',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'customMessage.SURVEY_CONTACT_ME_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'SURVEY_CONTACT_ME_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Enter Phone Number',
        key: 'customMessage.SURVEY_CONTACT_ME_MOBILE_NUMBER',
        type: 'customMessage',
        value: 'SURVEY_CONTACT_ME_MOBILE_NUMBER',
      },
      {
        defaultText: 'Invalid Email Address.',
        key: 'customMessage.SURVEY_IN_VALID_EMAID',
        type: 'customMessage',
        value: 'SURVEY_IN_VALID_EMAID',
      },
      {
        defaultText: 'Invalid Mobile Number.',
        key: 'customMessage.SURVEY_IN_VALID_PHONE',
        type: 'customMessage',
        value: 'SURVEY_IN_VALID_PHONE',
      },
      {
        defaultText: 'May we contact you about your experience?',
        key: 'customMessage.SURVEY_CONTACT_MAY_WE_CONTACT',
        type: 'customMessage',
        value: 'SURVEY_CONTACT_MAY_WE_CONTACT',
      },
      {
        defaultText: 'What is the best email address to reach you?',
        key: 'customMessage.SUVREY_CONTACT_BEST_EMAIL',
        type: 'customMessage',
        value: 'SUVREY_CONTACT_BEST_EMAIL',
      },
      {
        defaultText: 'Yes, also contact by me phone',
        key: 'customMessage.SURVEY_CONTACT_BY_PHONE',
        type: 'customMessage',
        value: 'SURVEY_CONTACT_BY_PHONE',
      },
      {
        defaultText: 'Email address is required.',
        key: 'customMessage.SURVEY_EMAIL_ADDRESS_REQUIRED',
        type: 'customMessage',
        value: 'SURVEY_EMAIL_ADDRESS_REQUIRED',
      },
      {
        defaultText: 'Mobile number is required.',
        key: 'customMessage.SURVEY_MOBILE_NUMBER_REQUIRED',
        type: 'customMessage',
        value: 'SURVEY_MOBILE_NUMBER_REQUIRED',
      },
      {
        defaultText: 'Tell us how to contact you',
        key: 'customMessage.SURVEY_HOW_TO_CONTACT_YOU',
        type: 'customMessage',
        value: 'SURVEY_HOW_TO_CONTACT_YOU',
      },
      {
        defaultText: 'By answering this 3 minute survey, you help us improve our service for you',
        key: 'customMessage.SURVEY_WELCOME_MESSAGE',
        type: 'customMessage',
        value: 'SURVEY_WELCOME_MESSAGE',
      },
      {
        defaultText: 'Thank you, Your feedback will help us improve our service.',
        key: 'customMessage.SURVEY_THANKS_MESSAGE',
        type: 'customMessage',
        value: 'SURVEY_THANKS_MESSAGE',
      },
    ]
  },
  AddCard: {
    screenLabel: 'Add Card Page',
    image: [addCardPage],
    customLabels: [
      {
        defaultText: 'Enter Credit Card',
        key: 'label.ENTER_CREDIT_CARD',
        type: 'label',
        value: 'ENTER_CREDIT_CARD',
      },
      {
        defaultText: 'Card Number',
        key: 'label.CARD_NUMBER',
        type: 'label',
        value: 'CARD_NUMBER',
      },
      {
        defaultText: 'Expiry Date',
        key: 'label.EXPIRY_DATE',
        type: 'label',
        value: 'EXPIRY_DATE',
      },
      {
        defaultText: 'Cvv',
        key: 'label.CVV',
        type: 'label',
        value: 'CVV',
      },
      {
        defaultText: 'Full name on card',
        key: 'label.FULL_NAME_CARD',
        type: 'label',
        value: 'FULL_NAME_CARD',
      },
      {
        defaultText: 'Exp date',
        key: 'label.EXP_DATE',
        type: 'label',
        value: 'EXP_DATE',
      },
      {
        defaultText: 'CVV',
        key: 'label.POST_CVV',
        type: 'label',
        value: 'POST_CVV',
      },
      {
        defaultText: 'Zip code',
        key: 'label.ZIP_CODE',
        type: 'label',
        value: 'ZIP_CODE',
      },
      {
        defaultText: 'Unable to proceed with payment',
        key: 'customMessage.UNABLE_PROCEED_WITH_PAYMENT',
        type: 'customMessage',
        value: 'UNABLE_PROCEED_WITH_PAYMENT',
      },
      {
        defaultText: 'save card',
        key: 'label.SAVE_CARD',
        type: 'label',
        value: 'SAVE_CARD',
      },
      {
        defaultText: 'Full name on card',
        key: 'label.STRIPE_FULL_NAME_ON_CARD',
        type: 'label',
        value: 'STRIPE_FULL_NAME_ON_CARD',
      },
      {
        defaultText: 'Name is required',
        key: 'customMessage.STRIPE_NAME_IS_REQUIRED',
        type: 'customMessage',
        value: 'STRIPE_NAME_IS_REQUIRED',
      },
      {
        defaultText: 'nameRequiredMessage',
        key: 'customMessage.STRIPE_NAME_REQ_MSG',
        type: 'customMessage',
        value: 'STRIPE_NAME_REQ_MSG',
      },
      {
        defaultText: 'stripeErrorMessage',
        key: 'customMessage.STRIPE_ERROR_MSG',
        type: 'customMessage',
        value: 'STRIPE_ERROR_MSG',
      },
      {
        defaultText: 'Card Name is required',
        key: 'customMessage.USA_EPAY_CARD_NAME_IS_REQUIRED',
        type: 'customMessage',
        value: 'USA_EPAY_CARD_NAME_IS_REQUIRED',
      },
      {
        defaultText: 'Invalid Zip code',
        key: 'customMessage.USA_EPAY_INVALID_ZIPCODE',
        type: 'customMessage',
        value: 'USA_EPAY_INVALID_ZIPCODE',
      },
      {
        defaultText: 'State can\'t be blank',
        key: 'customMessage.SPREEDLY_STATE_NOT_BLANK',
        type: 'customMessage',
        value: 'SPREEDLY_STATE_NOT_BLANK',
      },
      {
        defaultText: 'Zipcode can\'t be blank',
        key: 'customMessage.SPREEDLY_ZIPCODE_NOT_BLANK',
        type: 'customMessage',
        value: 'SPREEDLY_ZIPCODE_NOT_BLANK',
      },
      {
        defaultText: 'City can\'t be blank',
        key: 'customMessage.SPREEDLY_CITY_NOT_BLANK',
        type: 'customMessage',
        value: 'SPREEDLY_CITY_NOT_BLANK',
      },
      {
        defaultText: 'Address1 can\'t be blank',
        key: 'customMessage.SPREEDLY_ADDRESS1_NOT_BLANK',
        type: 'customMessage',
        value: 'SPREEDLY_ADDRESS1_NOT_BLANK',
      },
      {
        defaultText: 'Remove Card',
        key: 'label.REMOVE_CARD',
        type: 'label',
        value: 'REMOVE_CARD',
      },
    ]
  },
  PaymentDetailsCard: {
    screenLabel: 'Payment Card Page',
    image: [],
    customLabels: [
      {
        defaultText: 'First Name',
        key: 'label.CARD_FIRST_NAME',
        type: 'label',
        value: 'CARD_FIRST_NAME',
      },
      {
        defaultText: 'Last Name',
        key: 'label.CARD_LAST_NAME',
        type: 'label',
        value: 'CARD_LAST_NAME',
      },
      {
        defaultText: 'Credit Card Number',
        key: 'label.CARD_CREDIT_NUMBER',
        type: 'label',
        value: 'CARD_CREDIT_NUMBER',
      },
      {
        defaultText: 'CVV',
        key: 'label.CARD_CVV',
        type: 'label',
        value: 'CARD_CVV',
      },
      {
        defaultText: 'Expiration Date',
        key: 'label.CARD_EXP_DATE',
        type: 'label',
        value: 'CARD_EXP_DATE',
      },
      {
        defaultText: 'Zipcode',
        key: 'label.CARD_ZIPCODE',
        type: 'label',
        value: 'CARD_ZIPCODE',
      },
    ]
  },
  USAEPAY: {
    screenLabel: 'USA EPAY',
    image: [],
    customLabels: [
      {
        defaultText: 'Enter Credit/Debit Card',
        key: 'label.USA_CREDIT_CARD_LABEL',
        type: 'label',
        value: 'USA_CREDIT_CARD_LABEL',
      },
      {
        defaultText: 'Full name on card',
        key: 'label.USA_FULL_NAME',
        type: 'label',
        value: 'USA_FULL_NAME',
      },
      {
        defaultText: 'Zip code',
        key: 'label.USA_ZIPCODE',
        type: 'label',
        value: 'USA_ZIPCODE',
      },
    ]
  },
  myDataScreen: {
    screenLabel: 'My Data Screen',
    image: [],
    customLabels: [
      {
        defaultText: 'Request data',
        key: 'label.REQUEST_DATA',
        type: 'label',
        value: 'REQUEST_DATA',
      },
      {
        defaultText: 'Comment (optional)',
        key: 'label.REQUEST_DETAILS',
        type: 'label',
        value: 'REQUEST_DETAILS',
      },
      {
        defaultText: 'Your Data',
        key: 'label.YOUR_DATA',
        type: 'label',
        value: 'YOUR_DATA',
      },
      {
        defaultText: 'Delete data',
        key: 'label.DELETE_DATA',
        type: 'label',
        value: 'DELETE_DATA',
      },
      {
        defaultText: 'PROVIDE FEEDBACK',
        key: 'label.PROVIDE_FEEDBACK',
        type: 'label',
        value: 'PROVIDE_FEEDBACK',
      },
      {
        defaultText: 'Submit',
        key: 'label.MYDATA_SUBMIT',
        type: 'label',
        value: 'MYDATA_SUBMIT',
      },
      {
        defaultText: 'Please complete this form if you would like to request and manage your data. Before we can review your request, we must verify your identity and ownership of your account.',
        key: 'customMessage.DATA_REQUEST_MESSAGE',
        type: 'customMessage',
        value: 'DATA_REQUEST_MESSAGE',
      },
      {
        defaultText: 'What would you like to do?',
        key: 'customMessage.MY_DATA_MESSAGE',
        type: 'customMessage',
        value: 'MY_DATA_MESSAGE',
      },
    ]
  },
  charity: {
    screenLabel: 'Charity Screen',
    image: [],
    customLabels: [
      {
        defaultText: 'CHARITY TO DONATE',
        key: 'label.CHARITY_TO_DONATE',
        type: 'label',
        value: 'CHARITY_TO_DONATE',
      },
      {
        defaultText: 'Donate',
        key: 'label.CHARITY_DONATE',
        type: 'label',
        value: 'CHARITY_DONATE',
      },
      {
        defaultText: 'Other',
        key: 'label.CHARITY_OTHER',
        type: 'label',
        value: 'CHARITY_OTHER',
      },
      {
        defaultText: 'Round it up',
        key: 'label.CHARITY_ROUNDUP',
        type: 'label',
        value: 'CHARITY_ROUNDUP',
      },
      {
        defaultText: 'Edit charity',
        key: 'label.CHARITY_EDIT',
        type: 'label',
        value: 'CHARITY_EDIT',
      },
      {
        defaultText: 'Amount',
        key: 'label.CHARITY_AMOUNT',
        type: 'label',
        value: 'CHARITY_AMOUNT',
      },
      {
        defaultText: 'Enter charity amount',
        key: 'customMessage.ENTER_CHARITY_AMOUNT',
        type: 'customMessage',
        value: 'ENTER_CHARITY_AMOUNT',
      },
    ]
  },
  payOnlyMode: {
    screenLabel: 'Pay Only Mode',
    image: [payOnlyMode],
    customLabels: [
      {
        defaultText: 'Please give us just a moment to load this check.Press Retry to try again.',
        key: 'customMessage.PAYMENT_ONLY_MODE',
        type: 'customMessage',
        value: 'PAYMENT_ONLY_MODE',
      },
      {
        defaultText: 'Retry',
        key: 'label.PAY_ONLY_MODE_RETRY',
        type: 'label',
        value: 'PAY_ONLY_MODE_RETRY',
      },
    ]
  },
  listingPage: {
    screenLabel: 'Listing Page',
    image: [listingPage],
    customLabels: [
      {
        defaultText: 'Sorry, ordering is not supported in this location',
        key: 'customMessage.SORRY_ORDERING_IS_NOT_SUPPORTED_IN_THIS_LOCATION',
        type: 'customMessage',
        value: 'SORRY_ORDERING_IS_NOT_SUPPORTED_IN_THIS_LOCATION',
      },
      {
        defaultText: 'Choose Order Method',
        key: 'customMessage.CHOOSE_ORDER_METHOD',
        type: 'customMessage',
        value: 'CHOOSE_ORDER_METHOD',
      },
    ]
  },
  myCheckPage: {
    screenLabel: 'My Check',
    image: [myCheckPage],
    customLabels: [
      {
        defaultText: 'Reorder',
        key: 'label.REORDER',
        type: 'label',
        value: 'REORDER',
      },
    ]
  },
  mwebLinkReceipts: {
    screenLabel: 'Mweb Link Receipts',
    image: [],
    customLabels: [
      {
        defaultText: 'Sub Total',
        key: 'label.RECEIPT_SUB_TOTAL',
        type: 'label',
        value: 'RECEIPT_SUB_TOTAL',
      },
      {
        defaultText: 'Total',
        key: 'label.RECEIPT_TOTAL',
        type: 'label',
        value: 'RECEIPT_TOTAL',
      },
      {
        defaultText: 'Tax',
        key: 'label.RECEIPT_TAX',
        type: 'label',
        value: 'RECEIPT_TAX',
      },
      {
        defaultText: 'Tip',
        key: 'label.RECEIPT_TIP',
        type: 'label',
        value: 'RECEIPT_TIP',
      },
      {
        defaultText: 'Discount',
        key: 'label.RECEIPT_DISCOUNT',
        type: 'label',
        value: 'RECEIPT_DISCOUNT',
      },
      {
        defaultText: 'Delivery Fee',
        key: 'label.RECEIPT_DELIVERY_FEE',
        type: 'label',
        value: 'RECEIPT_DELIVERY_FEE',
      },
      {
        defaultText: 'Service Charge Amount',
        key: 'label.RECEIPT_SERVICE_CHARGE_AMOUNT',
        type: 'label',
        value: 'RECEIPT_SERVICE_CHARGE_AMOUNT',
      },
      {
        defaultText: 'Refund',
        key: 'label.RECEIPT_SALE_REFUND',
        type: 'label',
        value: 'RECEIPT_SALE_REFUND',
      },
      {
        defaultText: 'Card Number',
        key: 'label.RECEIPT_CARD_NUMBER',
        type: 'label',
        value: 'RECEIPT_CARD_NUMBER',
      },
      {
        defaultText: 'Begin Survey',
        key: 'label.RECEIPT_BEGIN_SURVEY',
        type: 'label',
        value: 'RECEIPT_BEGIN_SURVEY',
      },
      {
        defaultText: 'Loading',
        key: 'label.RECEIPT_LOADING',
        type: 'label',
        value: 'RECEIPT_LOADING',
      },
    ]
  },
};
